import ReplayIcon from '@mui/icons-material/Replay';
import DoubleScrollBar from 'react-double-scrollbar';
import {
  Box,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import React from 'react';
import { MainButton } from '../buttons';
import LoadingTable from '../Loading/LoadingTable';
import TablePagination from './TablePagination';
import { FlexBox, FlexBoxBetween } from '../tableItems';
import SearchInput from '../Search/SearchInput';

interface Props {
  HeadOfTable: JSX.Element;
  children: React.ReactNode;
  loading: boolean;
  error: boolean;
  itemsLength: number;
  fetchInfo: () => void;
  totalCells: number;
  countItems: number;
  pageSize: number;
  page: number;
  type: string;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (value: number) => void;
  pagination?: boolean;
  search?: boolean;
  searchProps?: {
    onKeyDown: () => void;
    value?: string;
    onChangeNormal: (query: string) => void;
    disabled: boolean;
    placeholder?: string;
  };
  searchContacts?: {
    value: boolean;
    onChange: () => void;
  };
  showCount?: boolean;
  countPoints?: { totalPoints: string; results: number };
}

const TableWrapper = ({
  HeadOfTable,
  children,
  loading,
  error,
  itemsLength,
  fetchInfo,
  countItems,
  pageSize,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  totalCells,
  type,
  pagination = true,
  search,
  searchProps,
  searchContacts,
  showCount,
  countPoints,
}: Props) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
      <Box
        sx={{
          overflow: 'auto',
          backgroundColor: 'white',
          flexGrow: 1,
        }}
        className="table_container"
      >
        {/* <DoubleScrollBar> */}
        <TableContainer
          style={{ tableLayout: 'fixed', display: 'table', width: '100%' }}
        >
          <Table
            aria-labelledby="tableTitle"
            sx={{
              borderRight: '1px solid #d9d9d9',
              backgroundColor: 'white',
              borderCollapse: 'collapse',
            }}
          >
            {HeadOfTable}
            <TableBody>
              {loading && (
                <LoadingTable quant={pageSize} cells={totalCells} type={type} />
              )}
              {children}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </DoubleScrollBar> */}
      </Box>

      {!loading && error && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: 2,
          }}
        >
          <Typography fontWeight={700} marginBottom={1.4}>
            Please try again
          </Typography>
          <MainButton endIcon={<ReplayIcon />} onClick={fetchInfo}>
            Try Again
          </MainButton>
        </Box>
      )}
      {!loading && !error && itemsLength === 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography fontWeight={700} marginBottom={1.4} marginTop={2}>
            No items found
          </Typography>
        </Box>
      )}
      {pagination && (
        <TablePagination
          showCount={showCount}
          countPoints={countPoints}
          mt={1.5}
          page={page}
          pageSize={pageSize}
          countItems={countItems}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default TableWrapper;
