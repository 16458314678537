import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { HistoryReq } from '../../../../models/Notes';
import Spinner from '../../../../components/Loading/Spinner';
import useLinkForm from '../../../../stores/links/useLinkForm';

interface LinkHistoryProps {
  history: HistoryReq[];
  loadingHistory: boolean;
}

const LinkHistory: React.FC<LinkHistoryProps> = ({
  history,
  loadingHistory,
}) => {
  const { linkForm } = useLinkForm();
  const [pagination, setPagination] = useState(5);
  const results = history.slice(0, pagination);

  useEffect(() => {
    if (linkForm.id && linkForm.id !== 0) {
      setPagination(5);
    }
  }, [linkForm.id]);

  return (
    <Box mt={1}>
      {loadingHistory && <Spinner />}
      {results.map(item => (
        <Grid container spacing={2} key={item.id} marginBottom={4}>
          <Grid item xs={2.5}>
            <Box width="100%">
              <div className="history_status">
                {item.status ?? item.new_status}
              </div>
            </Box>
          </Grid>
          <Grid item xs={9.5}>
            <Typography fontWeight={400} color="#787878" fontSize={13}>
              {dayjs(item.created).format('MM/DD/YYYY')} by{' '}
              <Typography
                display="inline"
                fontWeight={600}
                color="#787878"
                fontSize={13}
              >
                {item.user ? item.user.username : ''}
              </Typography>
            </Typography>
            <Typography marginTop={1} fontSize={13}>
              {item.body ?? ''}
            </Typography>
          </Grid>
        </Grid>
      ))}
      {history.length > pagination && (
        <Box display="flex" justifyContent="center" marginTop={1}>
          <IconButton
            size="large"
            sx={{ borderRadius: 20 }}
            onClick={() => setPagination(pagination + 5)}
          >
            <Typography>Load more...</Typography>
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default LinkHistory;
