import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Toolbar, Container, FormControlLabel } from '@mui/material';
import TitleButton from '../../components/Basic/TitleButton';
import SearchInput from '../../components/Search/SearchInput';
import TabParent from '../../components/Tab/TabParent';
import TabPanel from '../../components/Tab/TabPanel';
import ProspectingTable from './ProspectingTable';
import { fetchProspects } from '../../helpers/prospecting';
import {
  ProspectFieldsAssign,
  ProspectingResponse,
} from '../../models/Prospecting';
import Context from '../../context/UserContext';
import { UserContextType } from '../../models/User';
import FeedbackMessage from '../../components/FeedbackMessage';
import { BasicArray, MessagesInt } from '../../models/General';
import useSites from '../../customHooks/useSites';
import useOwners from '../../customHooks/useOwners';
import { fetchTemplatesFilter } from '../../helpers/templates';
import { TemplatesRequest } from '../../models/Templates';
import TablePagination from '../../components/Table/TablePagination';
import GeneralContext from '../../context/GeneralActions';
import { GlobalContextType } from '../../models/Context';
import { fetchAudiencesForForm } from '../../helpers/audience';
import { fetchAffinitiesForForm } from '../../helpers/affinities';
import { DefaultCheckbox } from '../../components/inputs';
import { FlexBoxBetween } from '../../components/tableItems';

const tabsArr = [
  { label: 'All', id: 0, disabled: false },
  { label: 'Eligible', id: 1, disabled: false },
  { label: 'Ineligible', id: 2, disabled: false },
];

const tabsArrDisabled = [
  { label: 'All', id: 0, disabled: true },
  { label: 'Eligible', id: 1, disabled: true },
  { label: 'Ineligible', id: 2, disabled: true },
];

const tabsNames = { '1': 'eligible', '2': 'ineligible', '0': '' };

const defaultProspectProps = {
  prospecting_result: 0,
  user: 0,
  site: 0,
  affinity: 0,
  template: 0,
  audience: 0,
};

const Prospecting = () => {
  const [tabs, setTabs] = useState(tabsArr);
  const abortController = useRef<AbortController | null>(null);
  const abortControllerAud = useRef<AbortController | null>(null);

  const { GlobalContext } = useContext(GeneralContext) as GlobalContextType;
  const { UserContext } = useContext(Context) as UserContextType;
  const [tab, setTab] = useState(0);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<MessagesInt>({
    message: '',
    severity: 'success',
  });
  const [prospects, setProspects] = useState<ProspectingResponse[]>([]);
  const [prospectsProps, setProspectProps] = useState<ProspectFieldsAssign[]>(
    [],
  );
  const [pastYear, setPastYear] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState('');

  const { owners } = useOwners({ withFields: true });

  const { sites } = useSites({ userId: UserContext.id, minimal: true });

  const [audiences, setAudiences] = useState<BasicArray[][]>([]);
  const [affinities, setAffinities] = useState<BasicArray[][]>([]);
  const [templatesProspecting, setTemplatesProspecting] = useState<
    TemplatesRequest[][]
  >([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState('');

  const handleMessage = (message: string, severity: 'success' | 'error') => {
    setFeedbackMessage({ message, severity });
    setFeedbackOpen(true);
  };

  const cancelRequest = () =>
    abortController.current && abortController.current.abort();

  const cancelRequestAud = () =>
    abortControllerAud.current && abortControllerAud.current.abort();

  const getAud = async () => {
    abortControllerAud.current = new AbortController();
    try {
      const resp = await fetchAudiencesForForm(
        GlobalContext.site_selected,
        abortControllerAud.current.signal,
      );
      return resp;
    } catch (err) {
      return [];
    }
  };

  const getProspectingData = async (
    newPage?: number,
    page_size?: number,
    filter?: string,
  ) => {
    abortController.current = new AbortController();

    setIsLoading(true);
    try {
      const resp = await fetchProspects(
        newPage ?? page,
        page_size ?? pageSize,
        GlobalContext.site_selected,
        pastYear,
        filter ?? status,
        search,
        abortController.current.signal,
      );
      setCount(resp.count);
      if (resp.results.length === 0) {
        setProspectProps([]);
        setTemplatesProspecting([]);
        setAudiences([]);
        setAffinities([]);
      } else {
        const transformProspects = resp.results.map(prospect => {
          return {
            prospecting_result: prospect.id,
            user: UserContext.id,
            site: GlobalContext.site_selected,
            affinity: 0,
            audience: 0,
            template: 0,
          };
        });
        setProspectProps(transformProspects);
        const respAud = await getAud();
        setTemplatesProspecting(new Array(resp.results.length).fill([]));
        setAudiences(new Array(resp.results.length).fill(respAud));
        setAffinities(new Array(resp.results.length).fill([]));
        setProspects(resp.results);
      }
      setIsLoading(false);
    } catch (err) {
      if (err) {
        setError(true);
        setIsLoading(false);
        setTemplatesProspecting([]);
        setAudiences([]);
        setAffinities([]);
      }
    }
  };

  // const updateAllProspects = async () => {
  //   const copyProspects = [...prospectsProps];
  //   setAudiences(new Array(copyProspects.length).fill([]));
  //   setAffinities(new Array(copyProspects.length).fill([]));
  //   setTemplatesProspecting(new Array(copyProspects.length).fill([]));
  //   const newResponse = copyProspects.map(item => {
  //     return {
  //       ...item,
  //       site: GlobalContext.site_selected,
  //       affinity: 0,
  //       audience: 0,
  //       template: 0,
  //     };
  //   });
  //   setProspectProps(newResponse);
  //   const respAudAff = await getAudAff();
  //   setAudiences(new Array(newResponse.length).fill(respAudAff.aud));
  //   setAffinities(new Array(newResponse.length).fill(respAudAff.aff));
  // };

  useEffect(() => {
    if (GlobalContext.site_selected === 0) setTabs(tabsArrDisabled);
    else setTabs(tabsArr);
    if (prospectsProps.length > 0) {
      setProspectProps([]);
      setProspects([]);
      setPage(1);
      setPageSize(10);
      if (search !== '') {
        getProspectingData(1, 10);
      }
    }
  }, [GlobalContext.site_selected, pastYear]);

  const handleSearch = () => {
    if (search === '') {
      setProspects([]);
      return;
    }
    getProspectingData();
  };

  useEffect(() => {
    cancelRequestAud();
    cancelRequest();
    setProspectProps([]);
    setProspects([]);
    const numberTab = tab.toString() as keyof typeof tabsNames;
    setPage(1);
    setPageSize(10);
    setStatus(tabsNames[numberTab]);
    if (search !== '') {
      getProspectingData(1, 10, tabsNames[numberTab]);
    }
  }, [tab]);

  const getTemplates = async (
    arrId: number,
    aud: number,
    aff: number,
    site_id: number,
  ) => {
    const newTemplates = [...templatesProspecting];

    try {
      const resp = await fetchTemplatesFilter(aud, aff, site_id);
      newTemplates[arrId] = resp;
      setTemplatesProspecting(newTemplates);
    } catch (err) {
      newTemplates[arrId] = [];
      setTemplatesProspecting(newTemplates);
    }
  };

  const getAudiences = async (siteId: number, arrId: number) => {
    const copyAudience = [...audiences];
    try {
      const resp = await fetchAudiencesForForm(siteId);
      copyAudience[arrId] = resp;
      setAudiences(copyAudience);
    } catch (err) {
      copyAudience[arrId] = [];
      setAudiences(copyAudience);
    }
  };

  const getAffinities = async (
    siteId: number,
    aud_id: number,
    arrId: number,
  ) => {
    const copyAffinity = [...affinities];
    try {
      const resp = await fetchAffinitiesForForm(aud_id, siteId);
      copyAffinity[arrId] = resp;
      setAffinities(copyAffinity);
    } catch (err) {
      copyAffinity[arrId] = [];
      setAffinities(copyAffinity);
    }
  };

  const handleUpdateProps = (
    arrId: number,
    field: keyof typeof defaultProspectProps,
    value: number,
  ) => {
    const newProps = [...prospectsProps];
    if (field === 'site') {
      const copyTemplates = [...templatesProspecting];
      const copyAffinity = [...affinities];
      copyTemplates[arrId] = [];
      copyAffinity[arrId] = [];
      setTemplatesProspecting(copyTemplates);
      setAffinities(copyAffinity);
      newProps[arrId].affinity = 0;
      newProps[arrId].audience = 0;
      newProps[arrId].template = 0;
      getAudiences(value, arrId);
    }
    if (field === 'audience') {
      newProps[arrId].template = 0;
      newProps[arrId].affinity = 0;
      const copyTemplates = [...templatesProspecting];
      copyTemplates[arrId] = [];
      setTemplatesProspecting(copyTemplates);

      getAffinities(newProps[arrId].site, value, arrId);
    }
    if (field === 'affinity') {
      newProps[arrId].template = 0;
      const copyTemplates = [...templatesProspecting];
      copyTemplates[arrId] = [];
      setTemplatesProspecting(copyTemplates);

      if (value !== 0 && newProps[arrId].audience !== 0)
        getTemplates(
          arrId,
          newProps[arrId].audience,
          value,
          newProps[arrId].site,
        );
    }
    newProps[arrId][field] = value;
    setProspectProps(newProps);
  };

  const handleBulkUpdate = (
    obj: ProspectFieldsAssign,
    selected: number[],
    selectedIndexes: number[],
    templateBulk?: TemplatesRequest[],
    audienceBulk?: BasicArray[],
    affinityBulk?: BasicArray[],
  ) => {
    const newProps = [...prospectsProps];
    const transform = newProps.map(item => {
      if (selected.includes(item.prospecting_result ?? 0)) {
        return {
          prospecting_result: item.prospecting_result,
          user: obj.user,
          site: obj.site,
          affinity: obj.affinity,
          audience: obj.audience,
          template: obj.template,
        };
      }
      return item;
    });
    if (templateBulk) {
      const copyAudience = [...audiences];
      const copyAffinity = [...affinities];
      const copyTemplate = [...templatesProspecting];
      selectedIndexes.forEach((index, i) => {
        copyAudience[index] = audienceBulk ?? [];
        copyAffinity[index] = affinityBulk ?? [];
        copyTemplate[index] = templateBulk;
      });
      setTemplatesProspecting(copyTemplate);
      setAudiences(copyAudience);
      setAffinities(copyAffinity);
    }
    setProspectProps(transform);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    getProspectingData(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number) => {
    getProspectingData(1, value);
    setPage(1);
    setPageSize(value);
  };

  const updatePages = () => {
    setPage(1);
    setPageSize(300);
  };

  return (
    <section className="right_columns">
      <Box>
        <Toolbar />
        <Box pt={4}>
          <Box px={2}>
            <TitleButton
              title="Prospecting"
              buttonText="Add Prospecting"
              button={false}
            />
          </Box>
          <Box marginTop={3}>
            <Box px={2}>
              <SearchInput
                placeholder=""
                id="search_prospecting"
                filter={false}
                onChangeNormal={value => setSearch(value)}
                onKeyDown={handleSearch}
                disabled={GlobalContext.site_selected === 0 || isLoading}
              />
            </Box>
            <FormControlLabel
              control={
                <DefaultCheckbox
                  disabled={GlobalContext.site_selected === 0 || isLoading}
                  checked={pastYear}
                  size="small"
                  onChange={e => setPastYear(e.target.checked)}
                />
              }
              label="Past Year"
              labelPlacement="end"
              sx={{
                '& .MuiFormControlLabel-label': { fontSize: 12 },
                marginTop: 0.5,
                marginLeft: 0.5,
              }}
            />
            <FlexBoxBetween
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginTop: 3,
              }}
            >
              <TabParent
                valueTab={tab}
                setTab={setTab}
                tabs={tabsArr}
                center={false}
              />
              <TablePagination
                page={page}
                pageSize={pageSize}
                countItems={count}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </FlexBoxBetween>
            <TabPanel value={tab} index={0}>
              <Box>
                <ProspectingTable
                  error={error}
                  handleMessage={handleMessage}
                  prospectsProps={prospectsProps}
                  updatePages={updatePages}
                  loading={isLoading}
                  fetchInfo={getProspectingData}
                  prospects={prospects}
                  handleUpdateProps={handleUpdateProps}
                  handleBulkUpdate={handleBulkUpdate}
                  owners={owners}
                  sites={sites}
                  pageSize={pageSize}
                  audiences={audiences}
                  affinities={affinities}
                  templatesProspecting={templatesProspecting}
                />
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Box marginBottom={3}>
                <ProspectingTable
                  error={error}
                  handleMessage={handleMessage}
                  prospectsProps={prospectsProps}
                  loading={isLoading}
                  fetchInfo={getProspectingData}
                  prospects={prospects}
                  handleUpdateProps={handleUpdateProps}
                  pageSize={pageSize}
                  updatePages={updatePages}
                  handleBulkUpdate={handleBulkUpdate}
                  owners={owners}
                  sites={sites}
                  audiences={audiences}
                  templatesProspecting={templatesProspecting}
                  affinities={affinities}
                />
              </Box>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Box marginBottom={3}>
                <ProspectingTable
                  error={error}
                  handleMessage={handleMessage}
                  prospectsProps={prospectsProps}
                  loading={isLoading}
                  pageSize={pageSize}
                  updatePages={updatePages}
                  fetchInfo={getProspectingData}
                  prospects={prospects}
                  handleUpdateProps={handleUpdateProps}
                  handleBulkUpdate={handleBulkUpdate}
                  owners={owners}
                  sites={sites}
                  audiences={audiences}
                  templatesProspecting={templatesProspecting}
                  affinities={affinities}
                />
              </Box>
            </TabPanel>
            <Box marginBottom={4}>
              <TablePagination
                page={page}
                pageSize={pageSize}
                countItems={count}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <FeedbackMessage
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        vertical="top"
        horizontal="right"
        severity={feedbackMessage.severity}
        message={feedbackMessage.message}
      />
    </section>
  );
};

export default Prospecting;
