import React, { useState } from 'react';
import { TemplateVisibilityColumns } from '../../models/Templates';
import FiltersTemplates from './Filters/FiltersTemplates';
import TemplatesContainer from './Table/TemplatesContainer';
import DrawerLinks from '../DefaultView/DrawerLinks';

const Templates = () => {
  const [clean, setClean] = useState(false);
  const [drawerForm, setDrawerForm] = useState(false);
  const [visibleColumns, setVisibleColumns] =
    useState<TemplateVisibilityColumns>({
      site__name: true,
      owner__username: true,
      affinity: true,
      audience: true,
      created: true,
      modified: true,
      status: true,
      notes_count: true,
    });
  const updateFormDrawer = () => setDrawerForm(!drawerForm);

  return (
    <div>
      <DrawerLinks openDrawer={drawerForm} openCloseDrawer={updateFormDrawer}>
        <FiltersTemplates
          columnsVisibility={visibleColumns}
          setColumnsVisible={setVisibleColumns}
          clean={clean}
        />
      </DrawerLinks>
      {/* <div className="left_columns">
      
      </div> */}
      <div className="right_columns">
        <TemplatesContainer
          columnsVisibility={visibleColumns}
          setClean={setClean}
          updateFormDrawer={updateFormDrawer}
          clean={clean}
        />
      </div>
    </div>
  );
};

export default Templates;
