import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid } from '@mui/material';
import SettingsStructure from '../SettingsStructure';
import ColumnInfo from './ColumnInfo/ColumnInfo';
import {
  deleteAffinity,
  fetchAffinities,
  fetchAffinitiesCounter,
  fetchAffinitiesSearch,
  updateAffinity,
  updateAffinityStatus,
} from '../../../helpers/affinities';
import {
  deleteAudience,
  fetchAudiences,
  fetchAudiencesCounter,
  updateAudienceStatus,
  updateAudience,
  fetchAudiencesSearch,
} from '../../../helpers/audience';
import MainDrawer from '../../../components/Drawers/MainDrawer';
import AddAudienceAffinity from '../../../components/Forms/AudienceAffinity/AddAudienceAffinity';
import { SeverityType } from '../../../models/General';
import DeleteModal from '../../../components/Modals/DeleteModal';
import { GlobalContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import useSites from '../../../customHooks/useSites';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import TabParent from '../../../components/Tab/TabParent';
import TabPanel from '../../../components/Tab/TabPanel';

const AudienceAffinity = () => {
  const { UserContext } = useContext(Context) as UserContextType;
  const { sites } = useSites({ userId: UserContext.id, minimal: true });
  const [render, setRender] = useState(0);
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [affinities, setAffinities] = useState<
    { id: number; name: string; status: string }[]
  >([]);
  const [audiences, setAudiences] = useState<
    { id: number; name: string; status: string }[]
  >([]);
  const [orderRequest, setOrderRequest] = useState({
    aud: 'name',
    aff: 'name',
  });
  const [statusRequest, setStatusRequest] = useState({
    aud: '',
    aff: '',
  });
  const [siteRequest, setSiteRequest] = useState({ aud: 0, aff: 0 });
  const [feedbackMessage, setFeedbackMessage] = useState<{
    message: string;
    severity: SeverityType;
  }>({ message: '', severity: 'success' });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [feedbackOpenAud, setFeedbackOpenAud] = useState(false);
  const [feedbackOpenAff, setFeedbackOpenAff] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingAff, setLoadingAff] = useState(false);
  const [loadingAud, setLoadingAud] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [alphabetAff, setAlphabetAff] = useState<[string, unknown][]>([]);
  const [alphabetAud, setAlphabetAud] = useState<[string, unknown][]>([]);

  const [tabValue, setTabValue] = useState(0);

  const [drawerOption, setDrawerOption] = useState(0);
  const [deleteObj, setDeleteObj] = useState<{
    title: string;
    dataType: string;
    id: number;
    options: { id: number; name: string }[];
  }>({
    title: '',
    dataType: '',
    id: 0,
    options: [],
  });

  const getAffinities = async (
    sort?: 'asc' | 'desc',
    status?: string,
    siteNumber?: number,
  ) => {
    if (sort)
      setOrderRequest({
        ...orderRequest,
        aff: sort === 'desc' ? '-name' : 'name',
      });

    if (status) {
      setStatusRequest({
        ...statusRequest,
        aff: status === 'all' ? '' : status,
      });
    }

    if (siteNumber) {
      setSiteRequest({
        ...siteRequest,
        aff: siteNumber === -10 ? 0 : siteNumber,
      });
    }

    setLoadingAff(true);

    const defaultSiteNumber =
      siteNumber === 0 || siteNumber === -10 ? undefined : siteNumber;

    const defaultSite = siteRequest.aff === 0 ? undefined : siteRequest.aff;

    try {
      const resp = await fetchAffinities(
        GlobalContext.affinity.page_filter,
        sort ? `${sort === 'desc' ? '-name' : 'name'}` : orderRequest.aff,
        status ? `${status === 'all' ? '' : status}` : statusRequest.aff,
        siteNumber ? defaultSiteNumber : defaultSite,
      );
      setAffinities(
        resp[GlobalContext.affinity.page_filter].map(
          (item: { id: any; name: any; status: any }) => {
            return { id: item.id, name: item.name, status: item.status };
          },
        ),
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAff(false);
    }
  };

  const getAudiences = async (
    sort?: 'asc' | 'desc',
    status?: string,
    siteNumber?: number,
  ) => {
    if (sort)
      setOrderRequest({
        ...orderRequest,
        aud: sort === 'desc' ? '-name' : 'name',
      });

    if (status) {
      setStatusRequest({
        ...statusRequest,
        aud: status === 'all' ? '' : status,
      });
    }

    if (siteNumber) {
      setSiteRequest({
        ...siteRequest,
        aud: siteNumber === -10 ? 0 : siteNumber,
      });
    }

    setLoadingAud(true);

    const defaultSiteNumber =
      siteNumber === 0 || siteNumber === -10 ? undefined : siteNumber;

    const defaultSite = siteRequest.aud === 0 ? undefined : siteRequest.aud;

    try {
      const resp = await fetchAudiences(
        GlobalContext.audience.page_filter,
        sort ? `${sort === 'desc' ? '-name' : 'name'}` : orderRequest.aud,
        status ? `${status === 'all' ? '' : status}` : statusRequest.aud,
        siteNumber ? defaultSiteNumber : defaultSite,
      );
      setAudiences(
        resp[GlobalContext.audience.page_filter].map(
          (item: { id: any; name: any; status: any }) => {
            return { id: item.id, name: item.name, status: item.status };
          },
        ),
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAud(false);
    }
  };

  useEffect(() => {
    if (render === 0 && affinities.length > 0 && audiences.length > 0)
      setRender(1);
  }, [affinities, audiences]);

  const searchAud = async () => {
    setLoadingAud(true);
    try {
      const resp = await fetchAudiencesSearch(
        GlobalContext.audience.search_filter,
        GlobalContext.audience.page_filter,
      );
      const data = resp[GlobalContext.audience.page_filter];
      const alph = Object.entries(resp).filter(item => {
        const aud = item[1] as any;
        return Array.isArray(aud) ? aud.length > 0 : aud > 0;
      });
      if (data.length === 0 && alph.length > 0) {
        const newResp = alph.find(item => {
          const aud = item[1] as number;
          return aud > 0;
        });
        if (newResp) {
          setGlobalContext({
            ...GlobalContext,
            audience: { ...GlobalContext.audience, page_filter: newResp[0] },
          });
          return;
        }
        setAudiences([]);
      } else setAudiences(data ?? []);
      setAlphabetAud(alph ?? []);
      setLoadingAud(false);
    } catch (err) {
      setFeedbackMessage({
        message: 'There was an error with your search',
        severity: 'error',
      });
      setFeedbackOpenAud(true);
      setLoadingAud(false);
    }
  };

  const searchAff = async () => {
    setLoadingAff(true);
    try {
      const resp = await fetchAffinitiesSearch(
        GlobalContext.affinity.search_filter,
        GlobalContext.affinity.page_filter,
      );
      const data = resp[GlobalContext.affinity.page_filter];
      const alph = Object.entries(resp).filter(item => {
        const aff = item[1] as any;
        return Array.isArray(aff) ? aff.length > 0 : aff > 0;
      });
      if (data.length === 0 && alph.length > 0) {
        const newResp = alph.find(item => {
          const aff = item[1] as number;
          return aff > 0;
        });
        if (newResp) {
          setGlobalContext({
            ...GlobalContext,
            affinity: { ...GlobalContext.affinity, page_filter: newResp[0] },
          });
          return;
        }
        setAffinities([]);
      } else setAffinities(data ?? []);
      setAlphabetAff(alph ?? []);
      setLoadingAff(false);
    } catch (err) {
      setFeedbackMessage({
        message: 'There was an error with your search',
        severity: 'error',
      });
      setFeedbackOpenAff(true);
      setLoadingAff(false);
    }
  };

  const changeStatus = async (
    obj: { status: 'active' | 'inactive' },
    id: number,
    option: string,
  ) => {
    try {
      if (option.toLocaleLowerCase() === 'audience')
        await updateAudience(obj, id);
      if (option.toLocaleLowerCase() === 'affinity')
        await updateAffinity(obj, id);
      return true;
    } catch (err) {
      return false;
    }
  };

  const showMessage = (
    message: string,
    option: 'Affinity' | 'Audience' | string,
    severity: SeverityType,
  ) => {
    setFeedbackMessage({ message, severity });
    if (option.toLowerCase() === 'audience') {
      setFeedbackOpenAud(true);
      getAudiences();
    }
    if (option.toLowerCase() === 'affinity') {
      setFeedbackOpenAff(true);
      getAffinities();
    }
  };

  const updateBulk = async (obj: { ids: number[] }, option: string) => {
    try {
      if (option.toLocaleLowerCase() === 'audience')
        await updateAudienceStatus(obj);
      if (option.toLocaleLowerCase() === 'affinity')
        await updateAffinityStatus(obj);
      showMessage('Bulk update was successful', option, 'success');
      return true;
    } catch (err) {
      showMessage('There was an error updating the status', option, 'error');
      return false;
    }
  };

  const deleteItem = async (id: number, option: string, newId?: number) => {
    setLoadingDelete(true);
    try {
      if (option.toLocaleLowerCase() === 'audience')
        await deleteAudience(id, newId ?? 0);
      if (option.toLocaleLowerCase() === 'affinity')
        await deleteAffinity(id, newId ?? 0);
      showMessage(`${option} was deleted successfully`, option, 'success');
    } catch (err) {
      showMessage(`The ${option} could not be deleted`, option, 'error');
    } finally {
      setLoadingDelete(false);
      setOpenModal(false);
    }
  };

  const handleDelete = (title: string, id: number, dataType: string) => {
    setDeleteObj({
      title,
      id,
      dataType,
      options:
        dataType.toLowerCase() === 'audience'
          ? audiences
              .filter(item => item.id !== id)
              .map(item => {
                return { id: item.id, name: item.name };
              })
          : affinities
              .filter(item => item.id !== id)
              .map(item => {
                return { id: item.id, name: item.name };
              }),
    });
    setOpenModal(true);
  };

  const getAudiencesCount = async () => {
    try {
      const resp = await fetchAudiencesCounter();
      const values: [string, unknown][] = Object.entries(resp).filter(
        item => item[1] !== 0,
      );
      setAlphabetAud(values);
      return values;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getAffinitiesCount = async () => {
    try {
      const resp = await fetchAffinitiesCounter();
      const values: [string, unknown][] = Object.entries(resp).filter(
        item => item[1] !== 0,
      );
      setAlphabetAff(values);
      return values;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const getFirstLetterAud = async () => {
    setLoadingAud(true);
    try {
      const resp = await getAudiencesCount();
      let findingResponseAudience = resp.find(item => /[a-z]/i.test(item[0]));

      if (!findingResponseAudience) {
        [findingResponseAudience] = resp;
      }

      setGlobalContext({
        ...GlobalContext,
        audience: {
          search_filter: '',
          page_filter: findingResponseAudience[0],
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearchAud = (query: string) => {
    if (query !== '') {
      setGlobalContext({
        ...GlobalContext,
        audience: {
          search_filter: query,
          page_filter: query[0].toLowerCase(),
        },
      });
      return;
    }
    getFirstLetterAud();
  };

  const getFirstLetterAff = async () => {
    setLoadingAff(true);
    try {
      const resp = await getAffinitiesCount();
      let findingResponseAffinity = resp.find(item => /[a-z]/i.test(item[0]));

      if (!findingResponseAffinity) {
        [findingResponseAffinity] = resp;
      }

      setGlobalContext({
        ...GlobalContext,
        affinity: {
          search_filter: '',
          page_filter: findingResponseAffinity[0],
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearchAff = (query: string) => {
    if (query !== '') {
      setGlobalContext({
        ...GlobalContext,
        affinity: {
          search_filter: query,
          page_filter: query[0].toLowerCase(),
        },
      });
      return;
    }
    getFirstLetterAff();
  };

  useEffect(() => {
    if (GlobalContext.audience.page_filter === '') return;
    if (GlobalContext.audience.search_filter !== '') searchAud();
    else if (render === 0) getAudiences();
    else Promise.all([getAudiencesCount(), getAudiences()]);
  }, [
    GlobalContext.audience.page_filter,
    GlobalContext.audience.search_filter,
  ]);

  useEffect(() => {
    if (GlobalContext.affinity.page_filter === '') return;
    if (GlobalContext.affinity.search_filter !== '') searchAff();
    else if (render === 0) getAffinities();
    else Promise.all([getAffinities(), getAffinitiesCount()]);
  }, [
    GlobalContext.affinity.page_filter,
    GlobalContext.affinity.search_filter,
  ]);

  const changeQueryAud = (letter: string) =>
    setGlobalContext({
      ...GlobalContext,
      audience: { ...GlobalContext.audience, page_filter: letter },
    });
  const changeQueryAff = (letter: string) =>
    setGlobalContext({
      ...GlobalContext,
      affinity: { ...GlobalContext.affinity, page_filter: letter },
    });

  const getFirstLetter = () => {
    setLoadingAff(true);
    setLoadingAud(true);
    Promise.all([getAffinitiesCount(), getAudiencesCount()]).then(resp => {
      const affinitiesResponse = resp[0];
      const audiencesResponse = resp[1];
      let findingResponseAffinity = affinitiesResponse.find(item =>
        /[a-z]/i.test(item[0]),
      );
      let findingResponseAudience = audiencesResponse.find(item =>
        /[a-z]/i.test(item[0]),
      );

      if (!findingResponseAffinity) {
        [findingResponseAffinity] = affinitiesResponse;
      }

      if (!findingResponseAudience) {
        [findingResponseAudience] = audiencesResponse;
      }
      setGlobalContext({
        ...GlobalContext,
        affinity: {
          page_filter: findingResponseAffinity[0],
          search_filter: '',
        },
        audience: {
          page_filter: findingResponseAudience[0],
          search_filter: '',
        },
      });
    });
  };

  useEffect(() => {
    getFirstLetter();
  }, []);

  return (
    <>
      <SettingsStructure title="Audience & Affinity" button={false} option={2}>
        <>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginTop: 1,
            }}
          >
            <TabParent
              valueTab={tabValue}
              setTab={setTabValue}
              tabs={[
                { id: 0, label: 'Audience', disabled: false },
                { id: 1, label: 'Affinity', disabled: false },
              ]}
              center={false}
            />
          </Box>
          <TabPanel value={tabValue} index={0}>
            <ColumnInfo
              title="Audience"
              handleDelete={handleDelete}
              sites={sites}
              alphabet={alphabetAud}
              updateStatus={changeStatus}
              feedbackMessage={feedbackMessage.message}
              feedbackOpen={feedbackOpenAud}
              current={GlobalContext.audience.page_filter}
              changeQuery={changeQueryAud}
              updateBulk={updateBulk}
              setFeedbackOpen={setFeedbackOpenAud}
              severity={feedbackMessage.severity}
              updateArr={setAudiences}
              searchFunc={handleSearchAud}
              add={() => {
                setDrawerOption(1);
                setOpenDrawer(true);
              }}
              options={audiences}
              loading={loadingAud}
              loadInfo={getAudiences}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ColumnInfo
              title="Affinity"
              updateBulk={updateBulk}
              sites={sites}
              feedbackMessage={feedbackMessage.message}
              feedbackOpen={feedbackOpenAff}
              setFeedbackOpen={setFeedbackOpenAff}
              alphabet={alphabetAff}
              updateArr={setAffinities}
              searchFunc={handleSearchAff}
              handleDelete={handleDelete}
              current={GlobalContext.affinity.page_filter}
              changeQuery={changeQueryAff}
              updateStatus={changeStatus}
              severity={feedbackMessage.severity}
              add={() => {
                setDrawerOption(2);
                setOpenDrawer(true);
              }}
              options={affinities}
              loading={loadingAff}
              loadInfo={getAffinities}
            />
          </TabPanel>
          {/* <Grid
            container
            sx={{ width: '100%', height: 'calc(100vh - 131.7px)' }}
          >
            <Grid
              item
              xs={6}
              sx={{
                height: '100%',
                width: '100%',
                overflowY: 'auto',
                borderRight: '1px solid rgba(0, 0, 0, 0.1)',
              }}
            >
              <ColumnInfo
                title="Audience"
                handleDelete={handleDelete}
                sites={sites}
                alphabet={alphabetAud}
                updateStatus={changeStatus}
                feedbackMessage={feedbackMessage.message}
                feedbackOpen={feedbackOpenAud}
                current={GlobalContext.audience.page_filter}
                changeQuery={changeQueryAud}
                updateBulk={updateBulk}
                setFeedbackOpen={setFeedbackOpenAud}
                severity={feedbackMessage.severity}
                updateArr={setAudiences}
                searchFunc={handleSearchAud}
                add={() => {
                  setDrawerOption(1);
                  setOpenDrawer(true);
                }}
                options={audiences}
                loading={loadingAud}
                loadInfo={getAudiences}
              />
            </Grid>
            <Grid item xs={6} sx={{ height: '100%', overflowY: 'auto' }}>
              <ColumnInfo
                title="Affinity"
                updateBulk={updateBulk}
                sites={sites}
                feedbackMessage={feedbackMessage.message}
                feedbackOpen={feedbackOpenAff}
                setFeedbackOpen={setFeedbackOpenAff}
                alphabet={alphabetAff}
                updateArr={setAffinities}
                searchFunc={handleSearchAff}
                handleDelete={handleDelete}
                current={GlobalContext.affinity.page_filter}
                changeQuery={changeQueryAff}
                updateStatus={changeStatus}
                severity={feedbackMessage.severity}
                add={() => {
                  setDrawerOption(2);
                  setOpenDrawer(true);
                }}
                options={affinities}
                loading={loadingAff}
                loadInfo={getAffinities}
              />
            </Grid>
          </Grid> */}
        </>
      </SettingsStructure>
      <DeleteModal
        open={openModal}
        closeModal={setOpenModal}
        loadingDelete={loadingDelete}
        option="Delete"
        id={deleteObj.id}
        deleteItem={deleteItem}
        title={deleteObj.title}
        dataType={deleteObj.dataType}
        selectOptions={deleteObj.options}
      />
      <MainDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
        {drawerOption === 1 && (
          <AddAudienceAffinity
            setOpenDrawer={setOpenDrawer}
            showMessage={showMessage}
            type="add"
            optionsArray={audiences.map(item => {
              return { id: item.id, name: item.name };
            })}
            option="Audience"
          />
        )}
        {drawerOption === 2 && (
          <AddAudienceAffinity
            setOpenDrawer={setOpenDrawer}
            showMessage={showMessage}
            type="add"
            optionsArray={affinities.map(item => {
              return { id: item.id, name: item.name };
            })}
            option="Affinity"
          />
        )}
      </MainDrawer>
    </>
  );
};

export default AudienceAffinity;
