import { Typography } from '@mui/material';
import React from 'react';

interface PaginateTextProps {
  condition: number;
  page: number;
  onClick: () => void;
  text: string;
}

const PaginateText: React.FC<PaginateTextProps> = ({
  page,
  condition,
  onClick,
  text,
}) => {
  return (
    <Typography
      fontSize={12}
      sx={{
        cursor: page - 1 === condition ? '' : 'pointer',
        borderRadius: '4px',
        border: '0.5px solid #dfdfdf',
        fontSize: '13px',
        padding: '4px 8px',
        color:
          page - 1 === condition ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.87)',

        '&:hover': {
          backgroundColor: page - 1 === condition ? '' : 'rgba(0, 0, 0, 0.04)',
        },

        '@media (min-width: 1900px)': {
          fontSize: '15px',
          padding: '5px 15px',
        },
      }}
      onClick={onClick}
    >
      {text}
    </Typography>
  );
};

export default PaginateText;
