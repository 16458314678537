/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import dayjs from 'dayjs';

const calendarIcon = () => {
  return (
    <CalendarMonthIcon
      sx={{
        fontSize: '18px',
        color: 'rgba(0, 0, 0, 0.2)',
        '&:hover': { color: 'rgba(0, 0, 0, 0.4)' },
      }}
    />
  );
};

interface Props {
  value: string | null;
  handleChangeDate: (date: string) => void;
  disabled?: boolean;
  filter?: boolean;
  fullWidth?: boolean;
}

const DateInput = ({
  value,
  handleChangeDate,
  disabled,
  filter,
  fullWidth,
}: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        disabled={disabled}
        components={{ OpenPickerIcon: calendarIcon }}
        InputProps={{
          sx: {
            flexDirection: 'row-reverse',
            fontSize: '13px',
            width: fullWidth ? '100%' : '135px',
          },
          readOnly: true,
        }}
        renderInput={params => (
          <Box width="100%">
            <TextField
              fullWidth
              onKeyDown={e => e.preventDefault()}
              {...params}
              sx={{
                '& .MuiInputBase-input': {
                  paddingY: '7px',
                  paddingRight: '5px',
                  paddingLeft: fullWidth ? '10px' : '7px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D7D7D7',
                    borderRadius: '6px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#ABABAB',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#ABABAB',
                  },
                },
              }}
            />
            {value && filter && (
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '.1px',
                  margin: 'auto',
                  right: '.2rem',
                  fontSize: 10,
                }}
                onClick={() => handleChangeDate('')}
              >
                <ClearIcon sx={{ fontSize: 18 }} />
              </IconButton>
            )}
          </Box>
        )}
        onChange={newValue =>
          handleChangeDate(
            dayjs(newValue).format(filter ? 'YYYY-MM-DD' : 'MM/DD/YYYY'),
          )
        }
      />
    </LocalizationProvider>
  );
};

export default DateInput;
