import { SitesRequestV2 } from './Sites';
import { LinkStatuses } from './Status';
import { Owners } from './User';

export interface GeneralContextInterface {
  links: LinksContext;
  users: UsersContext;
  templates: LinksContext;
  audience: { page_filter: string; search_filter: string };
  affinity: { page_filter: string; search_filter: string };
  unclaimed: LinksContext;
  claims: {
    order: string;
    query_filters: string;
    page: number;
    page_size: number;
  };
  master: {
    order: string;
    query_filters: string;
    page: number;
    page_size: number;
  };
  assigned_me: {
    page: number;
    page_size: number;
    query_filters: string;
    order: string;
  };
  site_selected: number;
}

interface LinksContext {
  order: string;
  page: number;
  page_size: number;
  query_filters: string;
  search_filter: string;
}

interface UsersContext {
  page: number;
  page_size: number;
  search: string;
  order: string;
}

export interface LoadingContextInterface {
  loading: boolean;
}

export const GeneralContextObj = {
  links: {
    order: '-created',
    page: 1,
    page_size: 50,
    query_filters: '',
    search_filter: '',
  },
  unclaimed: {
    order: '-modified',
    page: 1,
    page_size: 5,
    query_filters: '',
    search_filter: '',
  },
  templates: {
    order: '-modified',
    page: 1,
    page_size: 10,
    query_filters: '',
    search_filter: '',
  },
  users: {
    page: 1,
    page_size: 10,
    search: '',
    order: 'username',
  },
  audience: {
    page_filter: '',
    search_filter: '',
  },
  affinity: {
    page_filter: '',
    search_filter: '',
  },
  claims: {
    order: 'modified',
    query_filters: '',
    page: 1,
    page_size: 10,
  },
  master: {
    order: 'link',
    query_filters: '',
    page: 1,
    page_size: 10,
  },
  assigned_me: {
    page: 1,
    page_size: 10,
    query_filters: '',
    order: '-created',
  },
  site_selected: 0,
};

export type GlobalContextType = {
  GlobalContext: GeneralContextInterface;
  setGlobalContext: (value: GeneralContextInterface) => void;
};

export type LoadingContextType = {
  isLoadingContext: LoadingContextInterface;
  setIsLoading: (value: LoadingContextInterface) => void;
};

export interface RequestContextInterface {
  status: LinkStatuses[];
  owners: Owners[];
  sites: SitesRequestV2[];
}

export const RequestContextObj = {
  status: [],
  owners: [],
  sites: [],
};

export type RequestContextType = {
  RequestsContext: RequestContextInterface;
  setRequestsContext: (value: RequestContextInterface) => void;
};
