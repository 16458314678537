import React, { useContext, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GlobalContextType, LoadingContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import { BasicArray } from '../../../models/General';
import {
  BasicSelect,
  SelectFormBulk,
  SelectFormProspecting,
} from '../../../components/Forms/FormInputs';
import {
  filtersObjClaims,
  filtersObjClaimsWithStatus,
  filtersObjValuesWithOwner,
} from '../../../models/Filters';
import FiltersAccordion from '../../../components/Filters/FiltersAccordion';
import { Owners, UserContextType } from '../../../models/User';
import { TextInputField } from '../../../components/inputs';
import { onlyNumbers } from '../../../helpers/validateInputs';
import Context from '../../../context/UserContext';
import LoadingContext from '../../../context/LoadingContext';
import DateFilters from '../../../components/Filters/DateFilters';
import useFiltersClaims from '../../../stores/filters/useFiltersClaims';
import { FlexBoxBetween } from '../../../components/tableItems';
import { MainButton } from '../../../components/buttons';

interface FiltersClaimsProps {
  sites: BasicArray[];
  owners: Owners[];
  managers: BasicArray[];
  updateFormDrawer: () => void;
}

const linkType = [
  { value: 'Resource Page', label: 'Resource Page' },
  { value: 'Editorial', label: 'Editorial' },
  { value: 'Blog', label: 'Blog' },
  { value: 'Home Page', label: 'Home Page' },
  { value: 'Menu Drop-Down', label: 'Menu Drop-Down' },
  { value: 'Footer', label: 'Footer' },
];

const rolesAllowed = ['manager', 'strategist', 'administrator', 'dev admin'];

const rolesFilter = {
  administrator: filtersObjClaimsWithStatus,
  strategist: filtersObjClaimsWithStatus,
  manager: filtersObjClaims,
  'dev admin': filtersObjClaimsWithStatus,
};

const getFiltersByRole = (role: string) => {
  const roleUser = role as keyof typeof rolesFilter;
  return rolesFilter[roleUser];
};

const FiltersClaims = ({
  sites,
  owners,
  managers,
  updateFormDrawer,
}: FiltersClaimsProps) => {
  const filterClaims = useFiltersClaims();
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;
  const { UserContext } = useContext(Context) as UserContextType;

  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;

  const createQuery = () => {
    const copyFilters = JSON.parse(
      JSON.stringify(filterClaims.filterValuesDefault),
    );

    const ArrFilters: { id: string; value: string }[] = Object.assign(
      [],
      Object.values(copyFilters),
    );
    const arrayElements = ArrFilters.filter(item => item.value);
    const queryString = arrayElements.map(
      query => `&${query.id}=${query.value}`,
    );

    setGlobalContext({
      ...GlobalContext,
      links: { ...GlobalContext.links, order: '-created' },
      unclaimed: { ...GlobalContext.unclaimed, order: '-created' },
      master: { ...GlobalContext.master, order: 'link' },
      claims: {
        ...GlobalContext.claims,
        query_filters: queryString.join(''),
        page: 1,
        page_size: 10,
      },
    });
  };

  useEffect(() => {
    createQuery();
  }, [filterClaims.filterValuesDefault]);

  useEffect(() => {
    const filterObj = !rolesAllowed.includes(UserContext.profile.role)
      ? filtersObjValuesWithOwner(UserContext.id)
      : getFiltersByRole(UserContext.profile.role);

    filterClaims.updateFiltersChange(filterObj);
    filterClaims.updateFiltersDefault(filterObj);
  }, []);

  const updateDates = (id: string, value: string | null) => {
    let obj = { ...filterClaims.filterValuesChange };
    obj = { ...obj, [id]: { id, value } };
    filterClaims.updateFiltersChange(obj);
  };

  const updateOptions = (id: string, value: string) => {
    let obj = { ...filterClaims.filterValuesChange };
    obj = { ...obj, [id]: { id, value } };
    filterClaims.updateFiltersChange(obj);
  };

  const applyFilters = () =>
    filterClaims.updateFiltersDefault(filterClaims.filterValuesChange);

  const filtersArray = [
    {
      id: 1,
      title: 'Domain Authority',
      body: (
        <TextInputField
          value={filterClaims.filterValuesChange.domain_authority.value}
          name="da"
          disabled={false}
          size="small"
          onKeyDown={e => {
            if (e.key === 'Enter') applyFilters();
          }}
          onChange={e => updateOptions('domain_authority', e.target.value)}
          fullWidth
          placeholder="Enter DA"
          id="da"
        />
      ),
      disabled: false,
    },
    {
      id: 25,
      title: 'Domain Rating',
      body: (
        <TextInputField
          value={filterClaims.filterValuesChange.domain_rating.value}
          name="dr"
          disabled={false}
          size="small"
          onKeyDown={e => {
            if (e.key === 'Enter') applyFilters();
          }}
          onChange={e => updateOptions('domain_rating', e.target.value)}
          fullWidth
          placeholder="Enter DR"
          id="dr"
        />
      ),
      disabled: false,
    },
    {
      id: 2,
      title: 'Total Points',
      body: (
        <TextInputField
          value={filterClaims.filterValuesChange.total_points.value}
          name="total_points"
          disabled={false}
          size="small"
          onKeyDown={e => {
            if (e.key === 'Enter') applyFilters();
          }}
          onChange={e =>
            updateOptions('total_points', onlyNumbers(e.target.value))
          }
          fullWidth
          placeholder="Enter total points"
          id="total_points"
        />
      ),
      disabled: false,
    },

    {
      id: 3,
      title: 'Site',
      body: (
        <SelectFormProspecting
          required={false}
          options={sites}
          width
          disabled={false}
          clear
          onEnter={applyFilters}
          placeholder="Select Site"
          bigger
          onChange={(value: BasicArray | null) =>
            updateOptions('site', value ? value.id.toString() : '')
          }
          value={
            sites.find(
              item =>
                item.id ===
                parseInt(filterClaims.filterValuesChange.site.value ?? '0', 10),
            ) ?? { id: 0, name: '' }
          }
        />
      ),
      disabled: sites.length === 0,
    },
    {
      id: 4,
      title: 'Owner',
      body: (
        <SelectFormBulk
          required={false}
          options={owners}
          width
          disabled={!rolesAllowed.includes(UserContext.profile.role)}
          placeholder="Select Owner"
          clear
          onEnter={applyFilters}
          bigger
          onChange={(value: Owners | null) =>
            updateOptions('owner', value ? value.id.toString() : '')
          }
          value={
            owners.find(
              item =>
                item.id ===
                parseInt(
                  filterClaims.filterValuesChange.owner.value ?? '0',
                  10,
                ),
            ) ?? { id: 0, username: '' }
          }
        />
      ),
      disabled: owners.length === 0,
    },
    {
      id: 5,
      title: 'Link Type',
      body: (
        <BasicSelect
          required
          options={linkType}
          nameInput="link-type"
          filter
          disabled={false}
          value={filterClaims.filterValuesChange.link_type.value}
          onChange={(value: string) => updateOptions('link_type', value)}
        />
      ),
      disabled: false,
    },
    {
      id: 6,
      title: 'Status',
      body: (
        <BasicSelect
          required
          options={[
            { value: 'approved', label: 'Approved' },
            { value: 'unclaimed', label: 'Unclaimed' },
            { value: 'review', label: 'Review' },
            { value: 'rejected', label: 'Rejected' },
          ]}
          nameInput="status"
          filter
          disabled={false}
          value={filterClaims.filterValuesChange.status.value}
          onChange={(value: string) => updateOptions('status', value)}
        />
      ),
      disabled: false,
    },
    {
      id: 7,
      title: 'Created',
      body: (
        <DateFilters
          onEnter={applyFilters}
          disableInput={isLoadingContext.loading}
          min_id={filterClaims.filterValuesChange.min_added_date.id}
          min_value={filterClaims.filterValuesChange.min_added_date.value}
          max_id={filterClaims.filterValuesChange.max_added_date.id}
          max_value={filterClaims.filterValuesChange.max_added_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
    {
      id: 8,
      title: 'Modified',
      body: (
        <DateFilters
          onEnter={applyFilters}
          disableInput={isLoadingContext.loading}
          min_id={filterClaims.filterValuesChange.min_mod_date.id}
          min_value={filterClaims.filterValuesChange.min_mod_date.value}
          max_id={filterClaims.filterValuesChange.max_mod_date.id}
          max_value={filterClaims.filterValuesChange.max_mod_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
    // {
    //   id: 13,
    //   title: 'Found Date',
    //   body: (
    //     <DateInput
    //       value={filtersValues.found.value}
    //       filter
    //       fullWidth
    //       handleChangeDate={(value: string) =>
    //         setFiltersValues({
    //           ...filtersValues,
    //           found: {
    //             id: 'found',
    //             value: value === '' ? null : value,
    //           },
    //         })
    //       }
    //     />
    //   ),
    //   disabled: false,
    // },
    {
      id: 9,
      title: 'Date Approved',
      body: (
        <DateFilters
          onEnter={applyFilters}
          disableInput={isLoadingContext.loading}
          min_id={filterClaims.filterValuesChange.min_approved_date.id}
          min_value={filterClaims.filterValuesChange.min_approved_date.value}
          max_id={filterClaims.filterValuesChange.max_approved_date.id}
          max_value={filterClaims.filterValuesChange.max_approved_date.value}
          updateDates={updateDates}
        />
      ),
      disabled: false,
    },
  ];

  if (rolesAllowed.includes(UserContext.profile.role)) {
    filtersArray.splice(3, 0, {
      id: 4,
      title: 'Manager',
      body: (
        <SelectFormProspecting
          required={false}
          options={managers}
          width
          disabled={false}
          clear
          onEnter={applyFilters}
          placeholder="Select Manager"
          bigger
          onChange={(value: BasicArray | null) =>
            updateOptions('manager', value ? value.id.toString() : '')
          }
          value={
            managers.find(
              item =>
                item.id ===
                parseInt(
                  filterClaims.filterValuesChange.manager.value ?? '0',
                  10,
                ),
            ) ?? { id: 0, name: '' }
          }
        />
      ),
      disabled: managers.length === 0,
    });
  }

  return (
    <Box mt={2}>
      <FlexBoxBetween px={3}>
        <Typography sx={{ fontWeight: 900, fontSize: 16 }}>Filters</Typography>
        <IconButton
          aria-label="close"
          disabled={false}
          id="long-button"
          aria-haspopup="true"
          onClick={() => updateFormDrawer()}
        >
          <CloseIcon
            sx={{
              color: 'black',
              '&:hover': { color: 'red' },
            }}
          />
        </IconButton>
      </FlexBoxBetween>
      <FlexBoxBetween px={3} mt={1}>
        <MainButton
          size="small"
          disabled={isLoadingContext.loading}
          variant="contained"
          type="submit"
          onClick={() => {
            filterClaims.updateFiltersDefault(filterClaims.filterValuesChange);
            updateFormDrawer();
          }}
        >
          Apply Filters
        </MainButton>
        <Typography
          onClick={() => {
            if (isLoadingContext.loading) return;
            const obj = !rolesAllowed.includes(UserContext.profile.role)
              ? filtersObjValuesWithOwner(UserContext.id)
              : getFiltersByRole(UserContext.profile.role);
            filterClaims.updateFiltersChange(obj);
            filterClaims.updateFiltersDefault(obj);
            updateFormDrawer();
          }}
          sx={{
            fontSize: 14,
            cursor: 'pointer',
            ':hover': {
              color: 'rgba(0, 0, 0, 0.8)',
            },
          }}
        >
          Clear All
        </Typography>
      </FlexBoxBetween>
      <Box marginTop={2.4}>
        {filtersArray.map(filter => (
          <FiltersAccordion
            key={filter.id}
            title={filter.title}
            body={filter.body}
            disabled={filter.disabled}
            columnsVisibility={undefined}
            visibilityId="site"
          />
        ))}
      </Box>
    </Box>
  );
};

export default FiltersClaims;
