import { useEffect, useState } from 'react';
import { FastProjectsRequest } from '../models/Projects';
import { fetchFastProjects } from '../helpers/projects';

interface Props {
  id?: number;
}

const useFastProjects = ({ id }: Props) => {
  const [projects, setProjects] = useState<FastProjectsRequest[]>([]);
  const [loading, setLoading] = useState(false);

  const getProjects = () => {
    setLoading(true);
    fetchFastProjects(id ?? 0)
      .then(res => setProjects(res))
      .catch(err => setProjects([]))
      .finally(() => setLoading(false));
  };

  useEffect(getProjects, []);

  return { projects, loading };
};

export default useFastProjects;
