import { create } from 'zustand';
import { LinkObject } from '../../models/Links';

interface LinksListStore {
  linksList: LinkObject[];
  updateLinksList: (value: LinkObject[]) => void;
}

const useLinksList = create<LinksListStore>(set => ({
  linksList: [],

  updateLinksList: value => set({ linksList: value }),
}));

export default useLinksList;
