import {
  Checkbox,
  styled,
  TextField,
  InputBase,
  FormControlLabel,
  Radio,
  TextareaAutosize,
} from '@mui/material';

export const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    border: '2px solid #D7D7D7',
    borderRadius: '5px',
    fontSize: '14px',
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #ABABAB',
      borderRadius: '5px',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: '2px solid #ABABAB',
      borderRadius: '5px',
    },
    '& fieldset': {
      borderColor: '#ABABAB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ABABAB',
    },
  },
});

export const SearchTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: '14px',
    fontWeight: 550,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '2px solid #D7D7D7',
      borderRadius: '5px',
      fontSize: '14px',
    },
    '&:hover fieldset': {
      borderColor: '#ABABAB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ABABAB',
    },
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
    background: '#E8E8E8',
    borderColor: '#D7D7D7',
  },
});

export const SearchProjectsSites = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: '13px',
    fontWeight: 550,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #C7C7C7',
      borderRadius: '6px',
      fontSize: '13px',
    },
    '&:hover fieldset': {
      borderColor: '#CBCBCB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#CBCBCB',
    },
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
    background: '#E8E8E8',
    borderColor: '#D7D7D7',
  },
});

export const TextInputFieldError = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: '14px',
    fontWeight: 400,
    color: '#373737',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '2px solid #D7D7D7',
      borderRadius: '5px',
      fontSize: '14px',
    },
    '&:hover fieldset': {
      borderColor: '#ABABAB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ABABAB',
    },
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
    background: '#FFCDCD',
    borderColor: 'red',
    color: '#373737',
    fontWeight: 600,
  },
  '& .MuiFormHelperText-root': {
    background: 'none',
    fontWeight: 400,
  },
});

export const CustomTextAreaField = styled(TextField)({
  // width: '100%',
  // fontSize: '14px',
  // fontWeight: 400,
  // color: '#373737',
  // padding: '10px',
  // paddingBottom: '20px',
  // boxSizing: 'border-box',
  // borderRadius: '5px',
  // border: '2px solid #D7D7D7',
  // '&:hover': {
  //   borderColor: '#ABABAB',
  // },
  // '&:focus': {
  //   outline: 'none',
  //   borderColor: '#ABABAB',
  // },
  // '&:disabled': {
  //   cursor: 'not-allowed',
  //   background: '#E8E8E8',
  //   borderColor: '#D7D7D7',
  //   color: '#373737',
  //   fontWeight: 600,
  // },
  resize: 'none',

  width: '100%',
  '& .MuiInputBase-input': {
    fontSize: '12px',
    fontWeight: 400,
    color: '#373737',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '2px solid #D7D7D7',
      borderRadius: '5px',
      fontSize: '12px',
    },
    '&:hover fieldset': {
      borderColor: '#ABABAB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ABABAB',
    },
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
    background: '#E8E8E8',
    borderColor: '#D7D7D7',
    color: '#373737',
    fontWeight: 600,
  },
});

export const TextInputField = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: '12px',
    fontWeight: 400,
    color: '#373737',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '2px solid #D7D7D7',
      borderRadius: '5px',
      fontSize: '12px',
    },
    '&:hover fieldset': {
      borderColor: '#ABABAB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ABABAB',
    },
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
    background: '#E8E8E8',
    borderColor: '#D7D7D7',
    color: '#373737',
    fontWeight: 600,
  },
});

export const TextInputPendingOutreach = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: '12px',
    fontWeight: 400,
    color: '#373737',
  },
});

export const DefaultCheckbox = styled(Checkbox)({
  color: '#C7C7C7',
  '&.Mui-checked': {
    color: '#000000',
  },
  '&.MuiCheckbox-indeterminate': {
    color: '#000000',
  },
});

export const SelectInput = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    border: '2px solid #D7D7D7',
    padding: '8px 12px',
    fontSize: 12,
    color: '#373737',
    '&:focus': {
      border: '2px solid #D7D7D7',
    },
    '&:hover': {
      borderColor: '#ABABAB',
    },
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
    background: '#E8E8E8',
    borderColor: '#D7D7D7',
    color: '#373737',
    fontWeight: 600,
  },
}));

export const SelectBulk = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    border: '1px solid #D7D7D7',
    padding: '8px 12px',
    fontSize: 12,
    color: '#373737',
    '&:focus': {
      border: '1px solid #D7D7D7',
    },
    '&:hover': {
      borderColor: '#ABABAB',
    },
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
    background: '#E8E8E8',
    borderColor: '#D7D7D7',
    color: '#373737',
    fontWeight: 600,
  },
}));

export const SelectTime = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    border: '1px solid #D7D7D7',
    padding: '15px 20px',
    fontSize: 14,
    color: '#373737',
    '&:focus': {
      border: '1px solid #D7D7D7',
    },
    '&:hover': {
      borderColor: '#ABABAB',
    },
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
    background: '#E8E8E8',
    borderColor: '#D7D7D7',
    color: '#373737',
    fontWeight: 600,
  },
}));

export const SelectSnooze = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    border: '1px solid #D7D7D7',
    padding: '8px',
    width: '120px',
    fontSize: 13,
    color: '#373737',
    '&:focus': {
      border: '1px solid #D7D7D7',
    },
    '&:hover': {
      borderColor: '#ABABAB',
    },
  },
  '& .Mui-disabled': {
    cursor: 'not-allowed',
    background: '#E8E8E8',
    borderColor: '#D7D7D7',
    color: '#373737',
    fontWeight: 600,
  },
}));

export const FormWithLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '13px',
    fontWeight: 700,
  },
}));

export const CustomRadio = styled(Radio)(() => ({
  '&.Mui-checked': { color: '#717171' },
}));
