import {
  Collapse,
  IconButton,
  Table,
  TableCell,
  TableRow,
  Box,
  TableBody,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ProjectSitesFinalSave } from '../../../models/Projects';

interface Props {
  obj: ProjectSitesFinalSave;
}

const RowProjectSitesShow = ({ obj }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell
          component="th"
          scope="row"
          sx={{ fontWeight: 600, fontSize: 13 }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {obj.name}
        </TableCell>
        <TableCell align="right" />
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginLeft: 1 }}>
              <Table>
                <TableBody>
                  {obj.sites.map(site => (
                    <TableRow key={site.id}>
                      <TableCell component="th" scope="row">
                        <Typography sx={{ fontSize: 14 }}>
                          {site.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{site.email_alias}</TableCell>
                      <TableCell align="right">{site.date}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RowProjectSitesShow;
