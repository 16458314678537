import { Drawer } from '@mui/material';
import React from 'react';

interface Props {
  openDrawer: boolean;
  openCloseDrawer: () => void;
  children: React.ReactNode;
}

const DrawerLinks = ({ openDrawer, openCloseDrawer, children }: Props) => {
  return (
    <Drawer
      anchor="left"
      open={openDrawer}
      className="scroll-custom"
      keepMounted
      onClose={(e, r) => {
        openCloseDrawer();
      }}
      PaperProps={{ className: 'drawer-class-form' }}
    >
      {children}
    </Drawer>
  );
};

export default DrawerLinks;
