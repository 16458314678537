import { create } from 'zustand';
import { FiltersInterface, filtersObjWithDate } from '../../models/Filters';

interface FiltersLinksStore {
  filterValuesDefault: FiltersInterface;
  filterValuesChange: FiltersInterface;
  baseFilter: FiltersInterface | undefined;
  updateBaseFilter: (obj: FiltersInterface) => void;
  updateFiltersChange: (obj: FiltersInterface) => void;
  updateFiltersDefault: (obj: FiltersInterface) => void;
}

const useFiltersLinks = create<FiltersLinksStore>(set => ({
  filterValuesDefault: filtersObjWithDate(),
  filterValuesChange: filtersObjWithDate(),
  baseFilter: undefined,
  updateBaseFilter: obj => set({ baseFilter: obj }),
  updateFiltersChange: obj => set({ filterValuesChange: obj }),
  updateFiltersDefault: obj => set({ filterValuesDefault: obj }),
}));

export default useFiltersLinks;
