import React from 'react';
import { Box, Chip, MenuItem, Select, Typography } from '@mui/material';
import ReactPaginate from 'react-paginate';
import { FlexBox, FlexBoxBetween } from '../tableItems';

interface Props {
  pageSize: number;
  countItems: number;
  page: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (value: number) => void;
  mt?: number;
  showCount?: boolean;
  countPoints?: { totalPoints: string; results: number };
}

const TablePagination = ({
  pageSize,
  countItems,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  mt,
  showCount,
  countPoints,
}: Props) => {
  const lastPage = Math.ceil(countItems / pageSize);

  return (
    <FlexBoxBetween mx={1} mt={mt}>
      {countItems > 0 && (
        <>
          <FlexBox gap={2}>
            {showCount && (
              <Chip
                label={`Results Found: ${countItems}`}
                sx={{ fontWeight: 600 }}
              />
            )}
            {countPoints && (
              <>
                <Chip
                  label={`Total Points: ${countPoints.totalPoints}`}
                  sx={{ fontWeight: 600 }}
                />
                {/* <Chip
                  label={`Results Count: ${countPoints.results}`}
                  sx={{ fontWeight: 600 }}
                /> */}
              </>
            )}
          </FlexBox>
          {!showCount && <Box />}

          <FlexBox>
            <Typography
              fontSize={12}
              sx={{
                cursor: page - 1 === 0 ? '' : 'pointer',
                borderRadius: '4px',
                border: '0.5px solid #dfdfdf',
                fontSize: '13px',
                padding: '4px 8px',
                color:
                  page - 1 === 0 ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.87)',

                '&:hover': {
                  backgroundColor: page - 1 === 0 ? '' : 'rgba(0, 0, 0, 0.04)',
                },

                '@media (min-width: 1900px)': {
                  fontSize: '15px',
                  padding: '5px 15px',
                },
              }}
              onClick={() => {
                if (page - 1 === 0) return;
                handleChangePage(undefined, page - 1);
              }}
            >
              &larr; Prev
            </Typography>
            <FlexBox>
              <Select
                value={pageSize}
                onChange={e =>
                  handleChangeRowsPerPage(e.target.value as number)
                }
                sx={{
                  '& .MuiInputBase-input': {
                    border: 'none',
                    outline: 'none',
                    padding: '3px 8px',
                    fontSize: 12,
                    color: '#373737',

                    '@media (min-width: 1900px)': {
                      fontSize: '15px',
                      padding: '5px 15px',
                    },
                  },

                  marginX: 1,
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={300}>300</MenuItem>
              </Select>
            </FlexBox>

            <Typography
              fontSize={12}
              sx={{
                cursor: page === lastPage ? '' : 'pointer',
                fontSize: '13px',
                padding: '4px 8px',
                borderRadius: '4px',
                border: '0.5px solid #dfdfdf',
                mx: 0.4,
                color:
                  page - 1 === lastPage - 1
                    ? 'rgba(0, 0, 0, 0.4)'
                    : 'rgba(0, 0, 0, 0.87)',

                '&:hover': {
                  backgroundColor:
                    page === lastPage ? '' : 'rgba(0, 0, 0, 0.04)',
                },
                '@media (min-width: 1900px)': {
                  fontSize: '15px',
                  padding: '5px 15px',
                },
              }}
              onClick={() => {
                if (page === lastPage) return;
                handleChangePage(undefined, page + 1);
              }}
            >
              Next &rarr;
            </Typography>
          </FlexBox>
        </>
      )}
    </FlexBoxBetween>
  );
};

export default TablePagination;
