import { Typography, AccordionDetails, FormControlLabel } from '@mui/material';
import React, { useState, useEffect, useContext, useRef } from 'react';
import LoadingContext from '../../context/LoadingContext';
import { LoadingContextType } from '../../models/Context';
import { FastProjectsRequest, ProjectsRequest } from '../../models/Projects';
import {
  AccordionCustom,
  AccordionSummaryCustom,
} from '../Accordion/CustomAccordion';
import { DefaultCheckbox } from '../inputs';
import { FlexBox } from '../tableItems';

interface Props {
  projectsArr: FastProjectsRequest[];
  updateFiltersValue: (id: string, value: string) => void;
  clearAll: boolean;
  value: string;
  favFilters: number;
}

const SitesAccordion = ({
  projectsArr,
  updateFiltersValue,
  clearAll,
  value,
  favFilters,
}: Props) => {
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;
  const [sitesSelected, setSitesSelected] = useState<number[][]>([[]]);
  const [render, setRender] = useState(0);
  const timerId = useRef<any>(null);

  const handleClick = (arrId: number, id: number) => {
    const copyArr = [...sitesSelected];
    const selectedIndex = copyArr[arrId].indexOf(id);
    if (selectedIndex === -1) {
      const arr = [...copyArr[arrId], id];
      copyArr.splice(arrId, 1, arr);
    } else {
      const arr = [...copyArr[arrId]];
      arr.splice(selectedIndex, 1);
      copyArr.splice(arrId, 1, arr);
    }
    setSitesSelected(copyArr);
  };

  const handleAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    arrId: number,
    fullLength: number,
    originalSitesProjects: number[],
  ) => {
    const copyArr = [...sitesSelected];
    if (copyArr[arrId].length < fullLength) {
      copyArr.splice(arrId, 1, originalSitesProjects);
      setSitesSelected(copyArr);
      return;
    }
    copyArr.splice(arrId, 1, []);
    setSitesSelected(copyArr);
  };

  const isItemSelected = (arrId: number, id: number) =>
    sitesSelected[arrId].indexOf(id) !== -1;

  const timingFunc = () => {
    clearTimeout(timerId.current);
    timerId.current = null;
    const filter = sitesSelected.filter(e => e.length > 0);
    updateFiltersValue('sites_ids', filter.map(e => e.join(':')).join(','));
  };

  const transformFilter = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
      timerId.current = setTimeout(() => timingFunc(), 800);
    } else {
      timerId.current = setTimeout(() => timingFunc(), 800);
    }
  };

  useEffect(() => {
    if (render === 2) {
      setSitesSelected(new Array(projectsArr.length).fill([]));
    }
  }, [clearAll]);

  const compareValues = () => {
    const newValue = value
      .split(',')
      .map(item => item.split(':'))
      .map(e => e.map(Number));
    const filter = sitesSelected.filter(e => e.length > 0);
    const compare = filter.map(e => e.join(':')).join(',');
    if (compare !== value) {
      const newArray = projectsArr.map(item => {
        const sitesNewSelected = newValue
          .flat()
          .filter(number => item.sites.map(site => site.id).includes(number));
        return sitesNewSelected;
      });
      setSitesSelected(newArray);
    }
  };

  useEffect(() => {
    if (value === '' && sitesSelected.length === 0) return;
    if (render > 2) compareValues();
  }, [value]);

  useEffect(() => {
    if (render === 1 && value !== '') compareValues();
    if (render < 3) setRender(prevState => prevState + 1);
    if (render > 1 && favFilters > 0) transformFilter();
    if (render > 1 && favFilters === 0) transformFilter();
  }, [sitesSelected]);

  useEffect(() => {
    if (projectsArr.length > 0) {
      setRender(1);
      setSitesSelected(new Array(projectsArr.length).fill([]));
    }
  }, [projectsArr]);

  return (
    <>
      {projectsArr.length === 0 && (
        <Typography fontSize={13} fontWeight={700}>
          There was no projects found
        </Typography>
      )}

      {projectsArr.length > 0 &&
        projectsArr.length === sitesSelected.length && (
          <>
            {projectsArr.map((project, index) => {
              const originalSitesProject = project.sites.map(item => item.id);
              return (
                <AccordionCustom key={project.id} className="remove-line">
                  <AccordionSummaryCustom
                    className="thisSite"
                    aria-controls={`${project.name}-content`}
                    id={`${project.name}-header`}
                  >
                    <FlexBox>
                      <DefaultCheckbox
                        sx={{ padding: 0 }}
                        disabled={isLoadingContext.loading}
                        size="small"
                        checked={
                          sitesSelected[index].length === project.sites.length
                        }
                        indeterminate={
                          sitesSelected[index].length > 0 &&
                          sitesSelected[index].length < project.sites.length
                        }
                        onChange={e => {
                          handleAllClick(
                            e,
                            index,
                            project.sites.length,
                            originalSitesProject,
                          );
                        }}
                        onClick={e => e.stopPropagation()}
                      />
                      <Typography
                        fontSize={14}
                        marginLeft={0.8}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '185px',
                        }}
                      >
                        {project.name}
                      </Typography>
                    </FlexBox>
                  </AccordionSummaryCustom>
                  <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 1 }}>
                    {project.sites.map(site => (
                      <FlexBox key={site.id} sx={{ marginBottom: 1 }}>
                        <FormControlLabel
                          control={
                            <DefaultCheckbox
                              sx={{ padding: 0 }}
                              disabled={isLoadingContext.loading}
                              checked={isItemSelected(index, site.id)}
                              size="small"
                              onClick={() => handleClick(index, site.id)}
                            />
                          }
                          sx={
                            site.status !== 'active'
                              ? {
                                  '& .MuiFormControlLabel-label': {
                                    fontSize: 14,
                                    marginLeft: 0.8,
                                    color: 'gray',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '170px',
                                  },
                                }
                              : {
                                  '& .MuiFormControlLabel-label': {
                                    fontSize: 14,
                                    marginLeft: 0.8,
                                    color: '#000000',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '170px',
                                  },
                                }
                          }
                          label={site.name}
                        />
                      </FlexBox>
                    ))}
                  </AccordionDetails>
                </AccordionCustom>
              );
            })}
          </>
        )}
    </>
  );
};

export default SitesAccordion;
