/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef, useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { GenericAbortSignal } from 'axios';
import useLinkForm from '../../../stores/links/useLinkForm';

import { ContactsFormLink } from '../../../models/Forms';
import { validateEmail } from '../../../helpers/user';
import {
  ContactsValuesType,
  contactObjectDefault,
  contactValueDefault,
  contactValueDefaultError,
  contactValueDefaultLoading,
  validateResponseEmailContact,
} from '../../../helpers/contacts';
import { ContactForms } from '../FormInputs';
import { IS_USER_ADMIN_MANAGER } from '../../../constants/user';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import { isURL } from '../../../helpers/validateInputs';
import { getTemplateTranslate } from '../../../helpers/templates';
import { SeverityType } from '../../../models/General';

interface Props {
  contacts: ContactsFormLink[];
  originalContacts?: ContactsFormLink[];
  site: number;
  disableEditButton: boolean;
  handleDisableButtonContact: (value: boolean) => void;
  updateContacts: (contacts: ContactsFormLink[]) => void;
  loadingCannibalizationChange: boolean;
  pendingId?: number;
  contactsValuesOriginal: ContactsValuesType[];
  updateContactsValues: (copyContactsValues: ContactsValuesType[]) => void;
  disabledContactButton: boolean;
  type: 'add' | 'edit';
  doNotDeleteContact: string;
  allowOpenNewModalContact: boolean;
  openNewContactModal: () => void;
  sendMessage: (message: string, severity?: SeverityType) => void;
  updateLinkToPending: () => void;
  linkStatus: string;
  templateInfo: {
    template_id: number;
    link_url: string;
    link_id: number;
  };
  addTextNote: (message: string) => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  maxHeight: 700,
  overflowY: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

const ContactsComponent = ({
  contacts,
  templateInfo,
  site,
  disableEditButton,
  handleDisableButtonContact,
  updateContacts,
  originalContacts,
  loadingCannibalizationChange,
  pendingId,
  contactsValuesOriginal,
  updateContactsValues,
  disabledContactButton,
  type,
  doNotDeleteContact,
  allowOpenNewModalContact,
  openNewContactModal,
  sendMessage,
  updateLinkToPending,
  linkStatus,
  addTextNote,
}: Props) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const { linkForm } = useLinkForm();
  const [openModalNote, setOpenModalNote] = useState(false);
  const closeModalNote = () => setOpenModalNote(false);
  const [objectNoteContact, setObjectNoteContact] = useState({
    note: '',
    contactName: '',
  });

  const abortController = useRef<AbortController | null>(null);
  const [statusFromContacts, setStatusFromContacts] = useState<string[]>([]);
  const [render, setRender] = useState(0);
  const [removedOne, setRemovedOne] = useState(false);
  const [copyContacts, setCopyContacts] = useState(contacts);
  const [contactsValues, setContactsValues] = useState<ContactsValuesType[]>(
    contactsValuesOriginal,
  );

  useEffect(() => {
    setContactsValues(contactsValuesOriginal);
  }, [contactsValuesOriginal]);

  const [disabledContactSave, setDisabledContactSave] = useState(
    disabledContactButton,
  );
  const [validatingEmail, setValidatingEmail] = useState(false);
  const [disableButtonAdd, setDisableButtonAdd] = useState(false);

  useEffect(() => {
    updateContactsValues(contactsValues);
  }, [contactsValues]);

  const validateAllContacts = (validationArray?: ContactsValuesType[]) => {
    if (validatingEmail) return;
    const contactsValidation = copyContacts.filter(
      item =>
        (!item.is_form && item.email === '') ||
        item.status === '' ||
        (item.is_form &&
          (item.name === '' || item.form_text === '' || item.form_url === '')),
    );

    const contactsWithIndex = copyContacts.map((item, index) => {
      return { ...item, realIndex: index };
    });

    let errorForms = 0;
    let errorNotForms = 0;

    const contactsOnlyForm = contactsWithIndex.filter(item => item.is_form);
    const contactsNotForm = contactsWithIndex.filter(item => !item.is_form);

    const validateArray = validationArray ?? contactsValues;

    if (contactsOnlyForm.length > 0) {
      const valueValidURLS = contactsOnlyForm.map(item => {
        const isValid = isURL(item.form_url ?? '');
        return { validURL: isValid };
      });
      errorForms = valueValidURLS.filter(item => !item.validURL).length;
    }

    if (contactsNotForm.length > 0) {
      const getOnlyValuesEmails = contactsNotForm.map(item => {
        const value = validateArray[item.realIndex].validEmail;
        return { isValidContact: value };
      });
      errorNotForms = getOnlyValuesEmails.filter(
        item => !item.isValidContact,
      ).length;
    }

    const statusValidation = IS_USER_ADMIN_MANAGER.includes(
      UserContext.profile.role,
    )
      ? ['active']
      : copyContacts.filter(item => item.validation_status === 'active');

    if (
      contactsValidation.length > 0 ||
      errorForms > 0 ||
      errorNotForms > 0 ||
      statusValidation.length === 0
    ) {
      setDisabledContactSave(true);
    } else {
      setDisabledContactSave(false);
    }
  };

  const handleEmailValidation = async (
    value: string,
    contactVal: ContactsValuesType,
    id?: number,
    signal?: GenericAbortSignal,
  ) => {
    try {
      const resp = await validateEmail(value, site, id, signal);
      if (resp.valid_contact === false) {
        const contactValue = { ...contactValueDefault };
        contactValue.message = resp.message;
        contactValue.validEmail = false;
        if (resp.contact !== null && resp.duplicate_contact) {
          contactValue.contact = resp.contact;
          if (contactValue.contact.last_outreach === '')
            delete contactValue.contact.last_outreach;
          contactValue.previousChanged = true;
        } else contactValue.previousChanged = false;

        return contactValue;
      }
      const contactCopy = validateResponseEmailContact(resp);
      return contactCopy;
    } catch (err) {
      return contactVal;
    }
  };

  const handleEmailChange = async (
    value: string,
    contactVal: ContactsValuesType,
    arrId: number,
    signal?: GenericAbortSignal,
    copyOfContacts?: ContactsFormLink[],
  ) => {
    const copy = copyOfContacts ? [...copyOfContacts] : [...copyContacts];

    const sameId = type === 'edit' ? copy[arrId].id : undefined;

    const contactNewValue = await handleEmailValidation(
      value,
      contactVal,
      sameId,
      signal,
    );

    if (
      contactNewValue.contact === null &&
      contactVal.previousChanged &&
      !contactNewValue.previousChanged
    ) {
      copy[arrId] = {
        ...copy[arrId],
        email: value,
        form_text: '',
        form_url: '',
        name: '',
        status: 'open',
      };
      delete copy[arrId].id;
      delete copy[arrId].last_outreach;
      contactNewValue.contact = copy[arrId];
    }

    return {
      changeContact: contactNewValue.contact,
      contactNewValue,
    };
  };

  const handleAllEmailValidation = async (value: string, arrId: number) => {
    setDisableButtonAdd(true);
    if (value === '') {
      const contactCopy = { ...contactValueDefaultError };
      setContactsValues(() => {
        const prevCopy = [...contactsValues];
        prevCopy[arrId] = contactCopy;
        return prevCopy;
      });
      setValidatingEmail(false);
      setDisableButtonAdd(false);
      return;
    }
    const repeatEmails = copyContacts.filter(item => item.email === value);
    if (repeatEmails.length > 1) {
      const contactCopy = { ...contactValueDefaultError };
      contactCopy.message = 'Email already exist in the contacts';
      setContactsValues(() => {
        const prevCopy = [...contactsValues];
        prevCopy[arrId] = contactCopy;
        return prevCopy;
      });
      setValidatingEmail(false);
      setDisableButtonAdd(false);
      setDisabledContactSave(true);
      return;
    }
    const contactVal = contactsValues[arrId];
    const contactCopy = { ...contactVal };
    contactCopy.loading = true;
    contactCopy.message = '';
    setContactsValues(() => {
      const prevCopy = [...contactsValues];
      prevCopy[arrId] = contactCopy;
      return prevCopy;
    });

    const statusDefault =
      originalContacts && statusFromContacts[arrId] !== undefined
        ? statusFromContacts[arrId]
        : 'active';
    const response = await handleEmailChange(value, contactVal, arrId);
    const { contactNewValue } = response;

    console.log(response);

    if (contactNewValue.contact !== null) {
      const copy = [...copyContacts];
      copy[arrId] = contactNewValue.contact;
      const validation = contactNewValue.validEmail === true ? true : false;
      if (
        copy.filter(item => item.is_default).length === 0 ||
        copy[arrId].is_default
      ) {
        copy[arrId].is_default = IS_USER_ADMIN_MANAGER.includes(
          UserContext.profile.role,
        )
          ? true
          : validation;
      }
      copy[arrId].validation_status =
        contactNewValue.contact.validation_status ?? statusDefault;
      if (!contactNewValue.contact.id) delete copy[arrId].id;
      setCopyContacts(copy);
    } else {
      const copy = [...copyContacts];
      copy[arrId] = {
        ...copy[arrId],
        form_text: '',
        form_url: '',
        name: '',
        status: 'open',
      };
      const emailIsValid = contactNewValue.validEmail === true;
      if (
        copy.filter(item => item.is_default).length === 0 ||
        copy[arrId].is_default
      ) {
        copy[arrId].is_default = IS_USER_ADMIN_MANAGER.includes(
          UserContext.profile.role,
        )
          ? true
          : emailIsValid;
      }

      setCopyContacts(copy);
    }

    setContactsValues(() => {
      const prevCopy = [...contactsValues];
      prevCopy[arrId] = {
        ...contactNewValue,

        validEmail: IS_USER_ADMIN_MANAGER.includes(UserContext.profile.role)
          ? true
          : contactNewValue.validEmail,
      };
      return prevCopy;
    });
    setValidatingEmail(false);
    setDisableButtonAdd(false);
  };

  const validateAll = async (
    copyCont?: ContactsFormLink[],
    statusCopy?: string[],
    notChangeName?: boolean,
  ) => {
    setDisableButtonAdd(true);
    setDisabledContactSave(true);
    const loadingAll = new Array(contacts.length).fill(
      contactValueDefaultLoading,
    );
    setContactsValues(loadingAll);
    const newArrStatus = statusCopy ? [...statusCopy] : [...statusFromContacts];

    const originalCopy = copyCont ? [...copyCont] : [...copyContacts];
    const copy = copyCont ? [...copyCont] : [...copyContacts];
    abortController.current = new AbortController();
    const response = await Promise.all(
      copy.map(async (contact, index) => {
        if (contact.email === '' && !contact.is_form)
          return contactValueDefaultError;

        if (
          originalContacts &&
          originalContacts[index] !== undefined &&
          newArrStatus[index] === 'inactive'
        )
          return contactValueDefault;

        const repeatEmails = originalCopy.filter(
          item => item.email === contact.email,
        );
        if (removedOne && repeatEmails.length > 1) {
          return {
            ...contactValueDefaultError,
            message: 'Email already exist in the contacts',
            validEmail: IS_USER_ADMIN_MANAGER.includes(
              UserContext.profile.role,
            ),
          };
        }
        if (contact.is_form && !isURL(contact.form_url ?? ''))
          return contactValueDefaultError;

        if (
          contact.is_form &&
          (contact.form_text === '' ||
            contact.form_url === '' ||
            contact.name === '')
        )
          return contactValueDefaultError;

        if (
          contact.is_form &&
          contact.form_text !== '' &&
          contact.form_url !== '' &&
          contact.name !== ''
        )
          return contactValueDefault;
        // if (render === 0)
        //   return {
        //     contact,
        //     loading: false,
        //     validEmail: true,
        //     message: '',
        //     previousChanged: false,
        //   };
        try {
          const resp = await handleEmailChange(
            contact.email,
            loadingAll[index],
            index,
            abortController.current?.signal,
            copy,
          );

          const { contactNewValue } = resp;
          return {
            ...contactNewValue,
            validEmail: contactNewValue.validEmail,
          };
        } catch (err) {
          return contactValueDefault;
        }
      }),
    );
    setRemovedOne(false);

    const contactsCopy = copyCont ? [...copyCont] : [...copyContacts];
    response.forEach((item, index) => {
      if (item.contact !== null) {
        contactsCopy[index] = {
          ...item.contact,
          name: notChangeName ? contactsCopy[index].name : item.contact.name,
        };
      }
      contactsCopy[index].is_default =
        item.validEmail === false &&
        originalContacts &&
        originalContacts[index] !== undefined &&
        newArrStatus[index] === 'active'
          ? false
          : contactsCopy[index].is_default && item.validEmail === true
          ? true
          : false;

      if (contactsCopy[index].is_form)
        contactsCopy[index].validation_status = 'active';

      if (!contactsCopy[index].is_form)
        contactsCopy[index].validation_status =
          item.validEmail === false &&
          originalContacts &&
          originalContacts[index] !== undefined &&
          newArrStatus[index] === 'active'
            ? 'inactive'
            : contactsCopy[index].validation_status ?? 'active';
    });

    const uniqueContacts = [];
    const seenIds = new Set();

    const resultArr = contactsCopy.filter(obj => {
      if (obj.id === undefined || !seenIds.has(obj.id)) {
        uniqueContacts.push(obj);
        if (obj.id !== undefined) seenIds.add(obj.id);

        return true;
      }
      return false;
    });

    if (newArrStatus.length > 0 && originalContacts) {
      const statuses = resultArr.filter(
        (item, index) => newArrStatus[index] !== undefined,
      );
      setStatusFromContacts(
        statuses.map(item => {
          return item.validation_status;
        }),
      );
    }

    const contactsValidation = copyContacts.filter(
      item =>
        (!item.is_form && item.email === '') ||
        item.status === '' ||
        (item.is_form &&
          (item.name === '' || item.form_text === '' || item.form_url === '')),
    );
    const valuesValidation = contactsValues.filter(
      item => item.validEmail === false,
    );

    const statusValidation = IS_USER_ADMIN_MANAGER.includes(
      UserContext.profile.role,
    )
      ? ['active']
      : resultArr.filter(item => item.validation_status === 'active');

    setCopyContacts(resultArr);
    setContactsValues(
      response.map((item, index) => {
        if (resultArr[index].is_form) {
          return {
            ...item,
            message: item.message,
            validEmail: IS_USER_ADMIN_MANAGER.includes(UserContext.profile.role)
              ? true
              : item.validEmail,
          };
        }
        return {
          ...item,
          message: item.message,
          validEmail:
            !item.validEmail &&
            originalContacts &&
            originalContacts[index] !== undefined &&
            newArrStatus[index] !== undefined
              ? true
              : IS_USER_ADMIN_MANAGER.includes(UserContext.profile.role)
              ? true
              : item.validEmail,
        };
      }),
    );

    if (
      contactsValidation.length > 0 ||
      valuesValidation.length > 0 ||
      statusValidation.length === 0
    ) {
      setDisabledContactSave(true);
    } else {
      setDisabledContactSave(false);
    }
    setDisableButtonAdd(false);
  };

  const updateField = (value: string, arrId: number, field: string) => {
    const copy = [...copyContacts];
    if (field === 'email' && !validatingEmail) {
      setDisabledContactSave(true);
      setValidatingEmail(true);
    }
    if (field === 'email') {
      delete copy[arrId].id;
    }
    copy[arrId] = { ...copy[arrId], [field]: value };
    setCopyContacts(copy);
  };

  const getMessageForTemplate = async (contactName: string) => {
    try {
      const resp = await getTemplateTranslate(
        templateInfo.template_id,
        encodeURIComponent(templateInfo.link_url),
        templateInfo.link_id,
        contactName,
      );
      if (resp.body === '') sendMessage('Template does not contain a message');
      return resp.body;
    } catch (err) {
      sendMessage('Error while generating template');
      return '';
    }
  };

  const stripHtmlTags = (html: string) => {
    return html.replace(/<[^>]*>?/gm, '');
  };

  const updateNotes = async (arrId: number) => {
    const copy = [...copyContacts];
    const message = await getMessageForTemplate(copy[arrId].name);
    copy[arrId] = { ...copy[arrId], form_text: stripHtmlTags(message) };
    setCopyContacts(copy);
  };

  const updateIsForm = (value: boolean, arrId: number) => {
    const copy = [...copyContacts];
    copy[arrId] = {
      ...copy[arrId],
      is_form: value,
      name: '',
      email: '',
      form_text: '',
      form_url: '',
      is_default: false,
    };
    setCopyContacts(copy);
    const copyValues = [...contactsValues];
    copyValues[arrId] = {
      ...contactValueDefault,
      validEmail: false,
    };
    setContactsValues(copyValues);
  };

  const updateValidURL = (isValid: boolean, arrId: number) => {
    const copyValues = [...contactsValues];
    copyValues[arrId] = {
      ...contactValueDefault,
      validEmail: isValid,
    };
    setContactsValues(copyValues);
    validateAllContacts(copyValues);
  };

  const updateIsDefault = (value: boolean, arrId: number) => {
    const copy = [...copyContacts];
    if (value && copy.filter(item => item.is_default).length > 0) {
      sendMessage('You can only have one default contact.');
      return;
    }
    copy[arrId] = { ...copy[arrId], is_default: value };

    setCopyContacts(copy);
  };

  const cancelRequest = () =>
    abortController.current && abortController.current.abort();

  const removeContact = (arrId: number) => {
    if (
      originalContacts &&
      statusFromContacts.length > 0 &&
      statusFromContacts[arrId] !== undefined
    ) {
      const copyStatus = [...statusFromContacts];
      copyStatus.splice(arrId, 1);
      setStatusFromContacts(copyStatus);
    }
    const copy = [...copyContacts];
    copy.splice(arrId, 1);
    setCopyContacts(copy);
    const copyValue = [...contactsValues];
    copyValue.splice(arrId, 1);
    cancelRequest();
    setContactsValues(copyValue);
    setTimeout(() => {
      setRemovedOne(true);
    }, 900);
  };

  useEffect(() => {
    if (removedOne) {
      validateAll(undefined, statusFromContacts, true);
    }
  }, [removedOne]);

  const addContact = () => {
    const copy = [...copyContacts];
    copy.push(contactObjectDefault);
    setCopyContacts(copy);
    const copyValue = [...contactsValues];
    copyValue.push(contactValueDefaultError);
    setContactsValues(copyValue);
  };

  useEffect(() => {
    if (!validatingEmail && render !== 0) validateAllContacts();
  }, [validatingEmail]);

  useEffect(() => {
    updateContacts(copyContacts);
    if (
      linkStatus === 'contact_not_found' &&
      copyContacts.find(item => item.is_default === true) !== undefined
    )
      updateLinkToPending();
    if (render !== 0) validateAllContacts();
  }, [copyContacts]);

  useEffect(() => {
    if (render !== 0) {
      setCopyContacts(contacts);
      if (loadingCannibalizationChange) {
        setContactsValues(new Array(contacts.length).fill(contactValueDefault));
        validateAll(
          contacts,
          statusFromContacts.length === 0 && originalContacts
            ? originalContacts.map(item => {
                return item.validation_status;
              })
            : statusFromContacts,
        );
      }
      if (
        contacts.length !== contactsValues.length &&
        !loadingCannibalizationChange
      ) {
        setContactsValues(
          new Array(contacts.length).fill({
            ...contactValueDefault,
            validateEmail: true,
          }),
        );
      }
    }
  }, [contacts]);

  // useEffect(() => {
  //   if (originalContacts && originalContacts.length > 0) {
  //     setStatusFromContacts(
  //       originalContacts.map(item => {
  //         return item.validation_status;
  //       }),
  //     );
  //   }

  //   return () => {
  //     setStatusFromContacts([]);
  //   };
  // }, [originalContacts]);

  useEffect(() => {
    handleDisableButtonContact(disabledContactSave);
  }, [disabledContactSave]);

  useEffect(() => {
    if (render !== 0) validateAll();
  }, [site]);

  useEffect(() => {
    if (render === 0 && type === 'edit' && linkForm.id && linkForm.id > 0)
      validateAll(
        undefined,
        statusFromContacts.length === 0 && originalContacts
          ? originalContacts.map(item => {
              return item.validation_status;
            })
          : statusFromContacts,
      );
    setRender(1);
  }, [linkForm.id]);

  const disableAdd = () => {
    if (allowOpenNewModalContact && doNotDeleteContact === '') return false;

    return (
      disableEditButton ||
      disableButtonAdd ||
      (copyContacts.length === 1 &&
        copyContacts[0].is_form &&
        copyContacts[0].name === '')
    );
  };

  const handleReadMoreNote = (contactName: string, note: string) => {
    setObjectNoteContact({ contactName, note });
    setOpenModalNote(true);
  };

  return (
    <>
      {copyContacts.length === contactsValues.length && (
        <>
          <section>
            {copyContacts.map((contact, index) => (
              <ContactForms
                updateValidURL={updateValidURL}
                doNotDelete={
                  doNotDeleteContact !== '' &&
                  doNotDeleteContact === contact.email
                }
                isAdmin={IS_USER_ADMIN_MANAGER.includes(
                  UserContext.profile.role,
                )}
                key={index}
                updateNotes={updateNotes}
                addTextNote={addTextNote}
                handleReadMoreNote={handleReadMoreNote}
                validationStatus={statusFromContacts[index] ?? undefined}
                disableButtonAdd={disableButtonAdd}
                contact={contact}
                updateIsDefault={updateIsDefault}
                index={index}
                is_loading={contactsValues[index].loading}
                site={site}
                handleEmailChange={handleAllEmailValidation}
                updateField={updateField}
                updateIsForm={updateIsForm}
                template_id={templateInfo.template_id}
                removeContact={removeContact}
                disableEditButton={disableEditButton}
                contactValidation={contactsValues[index]}
                contactsLength={copyContacts.length}
              />
            ))}
            <IconButton
              disabled={disableAdd()}
              onClick={() =>
                allowOpenNewModalContact && doNotDeleteContact === ''
                  ? openNewContactModal()
                  : addContact()
              }
              sx={{
                borderRadius: 3,
                justifyContent: 'center',
                mb: 4,
                border: 1,
                borderColor: '#6EBD0C',
                '&:hover': {
                  backgroundColor: '#BAF372',
                },
                '&:disabled': {
                  backgroundColor: '#E9E9E9',
                  borderColor: 'white',
                },
              }}
            >
              <AddIcon
                sx={{
                  color: disableAdd() ? 'rgba(0, 0, 0, 0.8)' : '#6EBD0C',
                }}
              />
              <Typography
                color="rgba(0, 0, 0, 0.8)"
                fontWeight={600}
                fontSize={14}
                marginLeft={0.7}
              >
                Add Contact
              </Typography>
            </IconButton>
          </section>
          <Modal open={openModalNote}>
            <Box sx={style}>
              <Box justifyContent="end" display="flex">
                <IconButton onClick={closeModalNote} sx={{ fontSize: 17 }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Typography
                fontWeight={700}
                fontSize={17}
                textAlign="center"
                textTransform="capitalize"
              >
                {objectNoteContact.contactName}
              </Typography>
              <Box marginTop={2}>
                <Typography
                  marginTop={1.6}
                  fontSize={15}
                  marginLeft={0.8}
                  whiteSpace="pre-wrap"
                >
                  {objectNoteContact.note}
                </Typography>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default ContactsComponent;
