/* eslint-disable react/button-has-type */
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import 'react-quill/dist/quill.snow.css';

// Custom Toolbar Component
const CustomToolbar: React.FC<{ onUndo: () => void; onRedo: () => void }> = ({
  onUndo,
  onRedo,
}) => {
  return (
    <div id="custom-toolbar">
      <select className="ql-header" defaultValue="" onChange={e => e.persist()}>
        <option value="1" />
        <option value="2" />
        <option value="" />
      </select>
      <Tooltip title="Ordered List">
        <button className="ql-list" value="ordered">
          <FormatListNumberedIcon />
        </button>
      </Tooltip>
      <Tooltip title="Bullet List">
        <button className="ql-list" value="bullet">
          <FormatListBulletedIcon />
        </button>
      </Tooltip>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <select className="ql-color" />
      <select className="ql-background" />
      <select className="ql-align" />
      <Tooltip title="Undo">
        <IconButton onClick={onUndo} size="small">
          <UndoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Redo">
        <IconButton onClick={onRedo} size="small">
          <RedoIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CustomToolbar;
