import { HistoryReq, NotesReq } from '../models/Notes';
import {
  FastProjectsRequest,
  ProjectAddInt,
  ProjectsRequest,
} from '../models/Projects';
import APIClient from '../services/APIClient';

export const fetchProjects = async (
  idOwner?: number,
  idCategory?: number,
  order?: string,
  status?: boolean,
) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.get<ProjectsRequest[]>(
    `/projects/${idOwner ? `?owner=${idOwner}` : ''}${
      idCategory ? `?category_ids=${idCategory}` : ''
    }${order ? `&ordering=${order}` : ''}${status ? '&status=active' : ''}`,
  );
  return projectsResponse.data;
};

export const fetchFastProjects = async (idOwner: number) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.get<FastProjectsRequest[]>(
    `/projects/?owner=${idOwner}&simplified=true`,
  );
  return projectsResponse.data;
};

export const fetchSingleProject = async (id: number) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.get<ProjectsRequest>(
    `/projects/${id}`,
  );
  return projectsResponse.data;
};

export const addProject = async (obj: ProjectAddInt) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.post<ProjectsRequest>(
    `/projects/`,
    obj,
  );
  return projectsResponse.data;
};

export const updateProjectStatus = async (
  obj: { status: string; text_note: string },
  id: number,
) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.put<ProjectsRequest>(
    `/projects/${id}/`,
    obj,
  );
  return projectsResponse.data;
};

export const updateProject = async (obj: ProjectAddInt, id: number) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.put<ProjectsRequest>(
    `/projects/${id}/`,
    obj,
  );
  return projectsResponse.data;
};

export const deleteProject = async (id: number) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.delete(`/projects/${id}/`);
  return projectsResponse.data;
};

export const deactivateProject = async (id: number) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.post(
    `/projects/${id}/change-status/`,
  );
  return projectsResponse.data;
};

export const fetchProjectNotes = async (id: number) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.get<NotesReq[]>(
    `/projects/${id}/list-general-note/?ordering=-created`,
  );
  return projectsResponse.data;
};

export const addNoteProject = async (
  obj: { text_note: string },
  id: number,
) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.post(
    `/projects/${id}/add-note/`,
    obj,
  );
  return projectsResponse.data;
};

export const fetchProjectHistory = async (id: number) => {
  const clientAPI = new APIClient();
  const projectsResponse = await clientAPI.client.get<HistoryReq[]>(
    `/projects/${id}/list-status-note/?ordering=-created`,
  );
  return projectsResponse.data;
};
