import { GenericAbortSignal } from 'axios';
import { NotesReq } from '../models/Notes';
import {
  CreateTemplateInt,
  RequestOfTemplates,
  TemplatesRequest,
} from '../models/Templates';
import APIClient from '../services/APIClient';

const fetchTemplates = async (sites_ids: string) => {
  const clientAPI = new APIClient();
  const templatesResponse = await clientAPI.client.get<TemplatesRequest[]>(
    `/v2/templates/?sites_ids=${sites_ids}`,
  );
  return templatesResponse.data;
};

export const fetchTemplatesFilter = async (
  aud: number | string,
  aff: number | string,
  site?: number,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const templatesResponse = await clientAPI.client.get<TemplatesRequest[]>(
    `/v2/templates/?ordering=title&affinity=${aff}&audience=${aud}${
      site ? `&site=${site}` : ''
    }`,
    { signal },
  );
  return templatesResponse.data;
};

export const fetchAllTemplates = async (
  order?: string,
  page?: number,
  page_size?: number,
  queryFilters?: string,
  signal?: GenericAbortSignal,
) => {
  const clientAPI = new APIClient();
  const templatesResponse = await clientAPI.client.get<RequestOfTemplates>(
    `/v2/templates/${order ? `?ordering=${order}` : ''}${
      page ? `&page=${page}` : ''
    }${page_size ? `&page_size=${page_size}` : ''}${queryFilters ?? ''}`,
    { signal },
  );
  return templatesResponse.data;
};

export const createTemplate = async (obj: CreateTemplateInt) => {
  const clientAPI = new APIClient();
  const templatesResponse = await clientAPI.client.post<TemplatesRequest>(
    '/v2/templates/',
    obj,
  );
  return templatesResponse.data;
};

export const getTemplateTranslate = async (
  id: number,
  url: string,
  link_id: number,
  contact_name?: string,
) => {
  const clientAPI = new APIClient();
  const templatesResponse = await clientAPI.client.get<{
    title: string;
    subject: string;
    body: string;
  }>(
    `/templates/${id}/translated/?url=${url}${
      link_id !== 0 ? `&link_id=${link_id}` : ''
    }${contact_name ? `&contacts=${contact_name}` : ''}`,
  );
  return templatesResponse.data;
};

export const getTemplateTranslateEmail = async (
  id: number,
  url: string,
  link_id: number,
  contact_name?: string,
  contact_email?: string,
) => {
  const clientAPI = new APIClient();
  const templatesResponse = await clientAPI.client.get<{
    title: string;
    subject: string;
    body: string;
    unsubscribe_html: string;
    signature_html: string;
    pixel_tracker_url: string;
  }>(
    `/templates/${id}/translated/?url=${url}${
      link_id !== 0 ? `&link_id=${link_id}` : ''
    }${contact_name ? `&contacts=${contact_name}` : ''}${
      contact_email ? `&emails=${contact_email}` : ''
    }`,
  );
  return templatesResponse.data;
};

export const updateTemplate = async (obj: CreateTemplateInt, id: number) => {
  const clientAPI = new APIClient();
  const templatesResponse = await clientAPI.client.put<TemplatesRequest>(
    `/v2/templates/${id}/`,
    obj,
  );
  return templatesResponse.data;
};

export const bulkTemplateStatus = async (
  ids: number[],
  status: 'active' | 'inactive',
) => {
  const clientAPI = new APIClient();
  const templatesResponse = await clientAPI.client.put(
    `/v2/templates/update-bulk-status/?ids=${ids}&status=${status}`,
  );
  return templatesResponse.data;
};

export const fetchTemplateNotes = async (id: number) => {
  const clientAPI = new APIClient();
  const templatesResponse = await clientAPI.client.get<NotesReq[]>(
    `/v2/templates/${id}/list-general-note/?ordering=-created`,
  );
  return templatesResponse.data;
};

export const addNoteTemplate = async (
  obj: { text_note: string },
  id: number,
) => {
  const clientAPI = new APIClient();
  const templatesResponse = await clientAPI.client.post(
    `/v2/templates/${id}/add-note/`,
    obj,
  );
  return templatesResponse.data;
};

export default fetchTemplates;
