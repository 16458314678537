import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { FlexBoxBetween } from '../tableItems';

interface Props {
  title?: string;
  rightTitle?: {
    title: string;
    onClick: () => void;
  };
  children: React.ReactNode;
  pt?: number;
  mb?: number;
  pl?: number;
  pr?: number;
}

const BoxAddSettings = ({
  title,
  children,
  pt,
  mb,
  rightTitle,
  pl,
  pr,
}: Props) => {
  return (
    <Box
      paddingLeft={pl ?? 4}
      paddingRight={pr ?? 5}
      paddingTop={pt ?? 5}
      marginBottom={mb ?? 3}
    >
      {title && (
        <>
          <FlexBoxBetween>
            <Typography
              marginBottom={2}
              color="rgba(0, 0, 0, 0.8)"
              fontWeight={600}
              fontSize={15}
              textTransform="capitalize"
            >
              {title}
            </Typography>
            {rightTitle && (
              <Typography
                marginBottom={2}
                color="rgba(0, 0, 0, 0.8)"
                fontWeight={600}
                fontSize={15}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                textTransform="capitalize"
                onClick={rightTitle.onClick}
              >
                {rightTitle.title}
              </Typography>
            )}
          </FlexBoxBetween>
          <Divider />
        </>
      )}
      <Box paddingX={1.5} paddingTop={title ? 2 : 0}>
        {children}
      </Box>
    </Box>
  );
};

export default BoxAddSettings;
