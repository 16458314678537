import React, { useContext, useState, useEffect, useRef } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import TitleButton from '../../../components/Basic/TitleButton';
import RightColumns from '../../../components/Columns/RightColumns';
import SearchInput from '../../../components/Search/SearchInput';
import TabParent from '../../../components/Tab/TabParent';
import TableTemplates from './TableTemplates';
import TabPanel from '../../../components/Tab/TabPanel';
import {
  CreateTemplateInt,
  RequestOfTemplates,
  TemplateObjCreate,
  TemplatesRequest,
  TemplateVisibilityColumns,
} from '../../../models/Templates';
import { GlobalContextType, LoadingContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import LoadingContext from '../../../context/LoadingContext';
import { fetchAllTemplates } from '../../../helpers/templates';
import MainDrawer from '../../../components/Drawers/MainDrawer';
import AddTemplate from '../../../components/Forms/Template/AddTemplate';
import { MessagesInt, SeverityType } from '../../../models/General';
import FeedbackMessage from '../../../components/FeedbackMessage';
import { MainButton } from '../../../components/buttons';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import { ROLES_TEMPLATES } from '../../../constants/user';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import ApproveRejectTemp from '../../../components/Modals/ApproveRejectTemp';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';

interface Props {
  columnsVisibility: TemplateVisibilityColumns;
  setClean: React.Dispatch<React.SetStateAction<boolean>>;
  clean: boolean;
  updateFormDrawer: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 2,
};

const tabsArr = [
  { id: 0, label: 'All' },
  { id: 2, label: 'In Review' },
];

const TemplatesContainer = ({
  columnsVisibility,
  setClean,
  clean,
  updateFormDrawer,
}: Props) => {
  const abortController = useRef<AbortController | null>(null);
  const { UserContext } = useContext(Context) as UserContextType;
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const { isLoadingContext, setIsLoading } = useContext(
    LoadingContext,
  ) as LoadingContextType;

  const [tab, setTab] = useState(0);
  const [count, setCount] = useState(0);
  const [render, setRender] = useState(0);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerOption, setDrawerOption] = useState(0);

  const [popUp, setPopUp] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState<MessagesInt>({
    message: '',
    severity: 'success',
  });

  const [templates, setTemplates] = useState<TemplatesRequest[]>([]);
  const [openAppRej, setOpenAppRej] = useState(false);
  const [templateAppRej, setTemplateAppRej] = useState({
    obj: TemplateObjCreate,
    status: '',
  });
  const [templateEdit, setTemplateEdit] = useState<{
    obj: CreateTemplateInt;
    option: 'view' | 'clone' | 'edit';
    tab: number;
  }>({ obj: TemplateObjCreate, option: 'view', tab: 0 });

  const getTemplates = async () => {
    abortController.current = new AbortController();
    setLoading(true);
    setIsLoading({ loading: true });
    try {
      const response: RequestOfTemplates = await fetchAllTemplates(
        GlobalContext.templates.order,
        GlobalContext.templates.page,
        GlobalContext.templates.page_size,
        `${tab === 1 ? '&status=in review' : ''}${
          GlobalContext.templates.query_filters
        }${tab === 1 ? '' : GlobalContext.templates.search_filter}`,
        abortController.current.signal,
      );
      setTemplates(response.results);
      setCount(response.count);
      if (error) setError(false);
      if (render === 1) setRender(2);
      setLoading(false);
      setIsLoading({ loading: false });
    } catch (err) {
      if (err) {
        setError(true);
        setLoading(false);
        setIsLoading({ loading: false });
      }
    }
  };

  const cancelRequest = () =>
    abortController.current && abortController.current.abort();

  useEffect(() => {
    if (render !== 0) {
      cancelRequest();
      getTemplates();
    }
  }, [GlobalContext.templates]);

  useEffect(() => {
    setLoading(true);
    if (render !== 0) {
      setClean(tab === 1);
      return;
      setGlobalContext({
        ...GlobalContext,
        templates: {
          ...GlobalContext.templates,
          order: '-created',
          page: 1,
          search_filter: '',
        },
      });
    }
  }, [tab]);
  useEffect(() => {
    if (render === 0) {
      setGlobalContext({
        ...GlobalContext,
        templates: {
          query_filters: '',
          order: '-created',
          page: 1,
          page_size: 10,
          search_filter: '',
        },
      });
      setRender(1);
    }
  }, []);

  const updateSearch = (query: string) =>
    setGlobalContext({
      ...GlobalContext,
      templates: {
        ...GlobalContext.templates,
        search_filter: `&name=${query}`,
      },
    });

  const handleTemplate = (
    obj: TemplatesRequest,
    option: 'view' | 'clone' | 'edit',
    tabNotes?: number,
  ) => {
    const ownerDefault = obj.owner ? obj.owner.id : 0;
    const objTemp: CreateTemplateInt = {
      id: obj.id,
      title: option === 'clone' ? `Clone Of ${obj.title}` : obj.title,
      site: obj.site ? obj.site.id : 0,
      owner: option === 'clone' ? UserContext.id : ownerDefault,
      status: option === 'clone' ? 'in review' : obj.status,
      affinity: obj.affinity ? obj.affinity.id : 0,
      audience: obj.audience ? obj.audience.id : 0,
      subject: obj.subject,
      body: obj.body,
      automatic_greeting: obj.automatic_greeting ?? true,
      greetings: obj.greetings,
      signoff: obj.signoff,
    };
    setTemplateEdit({
      obj: objTemp,
      option,
      tab: tabNotes ?? 0,
    });
    setDrawerOption(2);
    setOpenDrawer(true);
  };

  const handleStatusTemplate = (
    obj: TemplatesRequest,
    status: 'active' | 'rejected',
  ) => {
    const objTemp: CreateTemplateInt = {
      id: obj.id,
      title: obj.title,
      site: obj.site ? obj.site.id : 0,
      owner: obj.owner.id,
      status,
      greetings: obj.greetings,
      signoff: obj.signoff,
      automatic_greeting: obj.automatic_greeting ?? true,
      affinity: obj.affinity ? obj.affinity.id : 0,
      audience: obj.audience ? obj.audience.id : 0,
      subject: obj.subject,
      body: obj.body,
    };
    setTemplateAppRej({
      obj: objTemp,
      status,
    });

    setOpenAppRej(true);
  };

  const showMessage = (
    message: string,
    severity: SeverityType,
    type: string,
  ) => {
    if (type === 'add') {
      setPopUp(true);
      getTemplates();
      return;
    }
    setFeedbackMessage({ message, severity });
    setFeedbackOpen(true);
    getTemplates();
  };

  return (
    <>
      <RightColumns pr={0} pl={0}>
        {/* <Box px={2}>
          <TitleButton
            title="Templates"
            filter
            buttonText="Add Template"
            button={
              sendBackObject(UserContext.profile.role, ROLES_TEMPLATES).add
            }
            buttonFunction={() => {
              setDrawerOption(1);
              setOpenDrawer(true);
            }}
          />
        </Box> */}
        <FlexBoxBetween px={2}>
          <FlexBox>
            <IconButton
              aria-label="filter"
              id="long-button"
              sx={{ border: 1, borderRadius: '10%' }}
              aria-haspopup="true"
              onClick={() => updateFormDrawer()}
            >
              <FilterListIcon
                sx={{
                  color: 'black',
                  '&:hover': { color: 'green' },
                }}
              />
            </IconButton>
            <Typography ml={1} fontSize={17} fontWeight="bold">
              Filters
            </Typography>
          </FlexBox>
          <Typography fontSize={24} fontWeight={900}>
            Templates
          </Typography>
          {sendBackObject(UserContext.profile.role, ROLES_TEMPLATES).add ? (
            <MainButton
              disabled={false}
              variant="contained"
              sx={{
                color: 'black',
                backgroundColor: '#BAF372',
                '&:hover': {
                  backgroundColor: '#BAF372',
                },
              }}
              type="submit"
              onClick={() => {
                setDrawerOption(1);
                setOpenDrawer(true);
              }}
            >
              Add Template
            </MainButton>
          ) : (
            <Box />
          )}
        </FlexBoxBetween>
        <Box marginTop={3}>
          <Box px={2}>
            <SearchInput
              placeholder=""
              id="search_template"
              filter={false}
              onChange={updateSearch}
              disabled={tab === 1}
            />
          </Box>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginTop: 3,
            }}
          >
            <TabParent
              valueTab={tab}
              setTab={setTab}
              tabs={tabsArr}
              center={false}
            />
          </Box>
          <TabPanel value={tab} index={0}>
            <TableTemplates
              templates={templates}
              loading={loading}
              countItems={count}
              showMessage={showMessage}
              tab={tab}
              handleStatusTemplate={handleStatusTemplate}
              error={error}
              handleTemplate={handleTemplate}
              review={false}
              fetchTemplates={getTemplates}
              columnsVisibility={columnsVisibility}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <TableTemplates
              templates={templates}
              loading={loading}
              showMessage={showMessage}
              review
              tab={tab}
              handleStatusTemplate={handleStatusTemplate}
              countItems={count}
              handleTemplate={handleTemplate}
              error={error}
              fetchTemplates={getTemplates}
              columnsVisibility={columnsVisibility}
            />
          </TabPanel>
        </Box>
      </RightColumns>

      <MainDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
        {drawerOption === 1 && (
          <AddTemplate
            setOpenDrawer={setOpenDrawer}
            type="add"
            showMessage={showMessage}
            review={false}
          />
        )}
        {drawerOption === 2 && (
          <AddTemplate
            setOpenDrawer={setOpenDrawer}
            type={templateEdit.option}
            templateObj={templateEdit.obj}
            review={tab === 1}
            showMessage={showMessage}
            tabNumber={templateEdit.tab}
          />
        )}
      </MainDrawer>
      <ApproveRejectTemp
        open={openAppRej}
        objTemplate={templateAppRej.obj}
        status={templateAppRej.status}
        closeModal={setOpenAppRej}
        showMessage={showMessage}
      />
      <FeedbackMessage
        open={feedbackOpen}
        setOpen={setFeedbackOpen}
        vertical="top"
        horizontal="right"
        severity={feedbackMessage.severity}
        message={feedbackMessage.message}
      />
      <Modal open={popUp} onClose={setPopUp}>
        <Box sx={style}>
          <Typography sx={{ mt: 2 }}>
            This template has been submitted for review
          </Typography>
          <Box marginTop={2} display="flex" justifyContent="center">
            <MainButton
              variant="contained"
              onClick={() => {
                setPopUp(false);
              }}
            >
              Okay
            </MainButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TemplatesContainer;
