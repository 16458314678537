import { Tabs, Tab } from '@mui/material';
import React from 'react';

interface Props {
  valueTab: number;
  setTab: (value: any) => void;
  tabs: { label: string; id: number; disabled?: boolean }[];
  center: boolean;
  smaller?: boolean;
  scrollable?: boolean;
  style?: boolean;
}

const TabParent = ({
  valueTab,
  setTab,
  tabs,
  center,
  smaller,
  scrollable,
  style,
}: Props) => {
  return (
    <Tabs
      value={valueTab}
      textColor="primary"
      scrollButtons="auto"
      variant={scrollable ? 'scrollable' : 'standard'}
      onChange={(e, value) => setTab(value)}
      TabIndicatorProps={{
        style: { backgroundColor: '#000000' },
      }}
      centered={center}
    >
      {tabs.map(item => (
        <Tab
          key={item.id}
          label={item.label}
          disabled={item.disabled}
          sx={style ? {} : { paddingX: 0, marginX: smaller ? '-10px' : '' }}
          className={
            valueTab === item.id ? 'tabs-tab_selected ' : 'tabs-tab_notselected'
          }
        />
      ))}
    </Tabs>
  );
};

export default TabParent;
