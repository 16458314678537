import React, { useContext, useEffect, useState } from 'react';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import relativetime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import {
  Box,
  IconButton,
  Link,
  Skeleton,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { ClaimPostPutInt, ClaimsInt } from '../../../models/Links';
import TableWrapper from '../../../components/Table/TableWrapper';
import TableHeadComp from '../../../components/Table/TableHead.tsx/TableHeadComp';
import { claimsHeadTable } from '../../../models/Table';
import { Order } from '../../../models/General';
import {
  FlexBox,
  FlexBoxBetween,
  TableCellCustom,
} from '../../../components/tableItems';
import MainDrawer from '../../../components/Drawers/MainDrawer';
import EditClaim from '../../../components/Forms/Links/EditClaim';
import GeneralContext from '../../../context/GeneralActions';
import { GlobalContextType } from '../../../models/Context';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import { claimCounts, editClaimLink } from '../../../helpers/claims';
import { StyledTableRow } from '../../../components/LinksTable/LinksTable';
import ClaimRejectModal from './ClaimsTable/ClaimRejectModal';
import ClaimApproveModal from './ClaimsTable/ClaimApproveModal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface ClaimsProps {
  loading: boolean;
  error: boolean;
  claimsArr: ClaimsInt[];
  fetchInfo: () => Promise<void>;
  handleMessage: (message: string, severity: 'success' | 'error') => void;
  count: number;
  tab: number;
}

const visibility = {
  link: true,
  site: true,
  owner: true,
  domain_authority: true,
  domain_rating: true,
  link_scan_status: true,
  rejected_reason: true,
  rejected_reason_detail: true,
  link_type: true,
  total_points: true,
  date_approved: true,
  modified: true,
  created: true,
  notes: true,
  duplicated: true,
  link__found: true,
  manager: true,
};

const rolesAppRej = [
  'manager',
  'account manager',
  'administrator',
  'dev admin',
  'strategist',
];

const Claims = ({
  loading,
  error,
  claimsArr,
  fetchInfo,
  handleMessage,
  count,
  tab,
}: ClaimsProps) => {
  dayjs.extend(relativetime);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [fullCount, setFullCount] = useState({ total: 0, count: 0 });
  const { UserContext } = useContext(Context) as UserContextType;
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('modified');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [claimEdit, setClaimEdit] = useState(0);

  const [openModalReject, setOpenModalReject] = useState(false);
  const closeModalReject = () => setOpenModalReject(false);
  const [objectClaimReject, setObjectClaimReject] = useState<{
    obj: ClaimPostPutInt;
    ownerName: string;
    id: number;
  }>({ obj: {} as ClaimPostPutInt, ownerName: '', id: 0 });

  const [openModalApprove, setOpenModalApprove] = useState(false);
  const closeModalApprove = () => setOpenModalApprove(false);

  const [objAppRej, setObjAppRej] = useState<{
    obj: ClaimPostPutInt;
    status: 'approved' | 'rejected';
    id: number;
  }>({ obj: {} as ClaimPostPutInt, status: 'approved', id: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const handleRequestSort = (property: string) => {
    const isSame = orderBy === property;
    let orderRequest = order;
    if (isSame) {
      orderRequest = order === 'asc' ? 'desc' : 'asc';
      setOrder(order === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(property);
    setGlobalContext({
      ...GlobalContext,
      claims: {
        ...GlobalContext.claims,
        order: `${orderRequest === 'desc' ? '-' : ''}${property}`,
      },
    });
  };

  const handleEdit = (claim: number) => {
    setClaimEdit(claim);
    setOpenDrawer(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setGlobalContext({
      ...GlobalContext,
      claims: { ...GlobalContext.claims, page: newPage },
    });
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setGlobalContext({
      ...GlobalContext,
      claims: {
        ...GlobalContext.claims,
        page: 1,
        page_size: value,
      },
    });
    window.scrollTo(0, 0);
  };

  const handleModalClaim = (item: ClaimsInt, status: 'reject' | 'approve') => {
    const obj: ClaimPostPutInt = {
      link_owner: item.link_owner ? item.link_owner.id : 0,
      status: item.status,
      site: item.site ? item.site.id : 0,
      url: item.url,
      template: item.template ? item.template.id : 0,
      affinity: item.affinity ? item.affinity.id : 0,
      is_client_facing: item.is_client_facing ?? true,
      owner: item.owner.id,
      link_type: item.link_type,
      domain_authority: item.domain_authority,
      domain_rating: item.domain_rating,
      notes: item.notes,
      total_points: item.total_points,
      points: item.points,
      targets: item.targets,
      created: item.created,
    };
    if (item.audience) obj.audience = item.audience.id;
    if (item.project) obj.project = item.project.id;
    if (item.date_approved) obj.date_approved = item.date_approved;
    if (item.found_date) obj.found_date = item.found_date;
    setObjectClaimReject({ obj, ownerName: item.owner.username, id: item.id });
    if (status === 'reject') setOpenModalReject(true);

    if (status === 'approve') setOpenModalApprove(true);
  };

  const handleSubmit = async (editObject?: ClaimPostPutInt, id?: number) => {
    setIsLoading(true);
    try {
      await editClaimLink(editObject ?? objAppRej.obj, id ?? objAppRej.id);
      handleMessage('Claim updated successfully', 'success');
      fetchInfo();
      setOpenModalReject(false);
      setOpenModalApprove(false);
    } catch (err) {
      const errorMessage = err as any;
      if (
        errorMessage.data.targets &&
        errorMessage.data.targets.find(
          (item: { destination_url?: any }) => item.destination_url,
        )
      ) {
        handleMessage('Malformed link for Destination Url', 'error');
      } else {
        handleMessage('Error updating claim', 'error');
      }
      setOpenModalReject(false);
      setOpenModalApprove(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getCount = async () => {
    setIsLoadingCount(true);
    try {
      const resp = await claimCounts(
        GlobalContext.claims.query_filters.replace('&', '?'),
      );
      setFullCount({ count: resp.results_count, total: resp.total_points_sum });
    } catch (err) {
      setFullCount({ count: 0, total: 0 });
    } finally {
      setIsLoadingCount(false);
    }
  };

  useEffect(() => {
    if (tab === 2) getCount();
  }, [GlobalContext.claims.query_filters]);

  return (
    <>
      <TableWrapper
        search
        countPoints={{
          totalPoints: fullCount.total.toFixed(2),
          results: fullCount.count,
        }}
        loading={loading}
        error={error}
        type="Claims"
        pagination
        totalCells={15}
        fetchInfo={fetchInfo}
        itemsLength={claimsArr.length}
        showCount
        countItems={count}
        pageSize={GlobalContext.claims.page_size}
        page={GlobalContext.claims.page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        HeadOfTable={
          <TableHeadComp
            columnsVisibility={visibility}
            headCells={claimsHeadTable}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
        }
      >
        {claimsArr.map((row, index) => {
          const labelId = `claims-table-checkbox-${index}`;
          return (
            <StyledTableRow
              hover
              selected={false}
              key={row.id}
              className="column-row"
            >
              <TableCell
                className="first_column"
                component="th"
                sx={{ backgroundColor: index % 2 === 0 ? '#eeeeee' : '' }}
                id={labelId}
                scope="row"
                padding="none"
              >
                <FlexBoxBetween
                  sx={{
                    color: '#373737',
                    paddingTop: 2.3,
                    paddingBottom: 2.3,
                    paddingRight: 1,
                    paddingLeft: 1,
                  }}
                >
                  <FlexBox>
                    <Box marginLeft={1}>
                      <Link
                        href={row.url}
                        target="_blank"
                        color="#373737"
                        underline="hover"
                        className="table-link_text-prospect"
                      >
                        {row.url}
                      </Link>
                    </Box>
                  </FlexBox>
                  <FlexBox>
                    {rolesAppRej.includes(UserContext.profile.role) && (
                      <>
                        <Tooltip title="Approve Claim">
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={() => handleModalClaim(row, 'approve')}
                          >
                            <CheckIcon
                              sx={{
                                color: '#6EBD0C',
                                '&:hover': { color: '#6EBD0C' },
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Reject Claim">
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={() => handleModalClaim(row, 'reject')}
                          >
                            <ClearIcon
                              sx={{
                                color: '#EB9393',
                                '&:hover': { color: '#EB9393' },
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Tooltip title="Edit Claim">
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-haspopup="true"
                        onClick={() => handleEdit(row.id)}
                      >
                        <ModeEditOutlineOutlinedIcon
                          sx={{
                            color: '#7F7F7F',
                            '&:hover': { color: '#7F7F7F' },
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </FlexBox>
                </FlexBoxBetween>
              </TableCell>
              <TableCellCustom className="table_overflow-text">
                {row.link_type}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                {row.site.name}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                {row.manager ? row.manager.username : ''}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                {row.owner.username}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                {row.duplicated && <CheckIcon />}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                {row.domain_authority}
              </TableCellCustom>{' '}
              <TableCellCustom className="table_overflow-text">
                {row.domain_rating}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                <div className="status_row">{row.status}</div>
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                {row.rejected_reason ? (
                  <FlexBox>
                    <Tooltip title={row.rejected_reason}>
                      <IconButton sx={{ fontSize: 17 }}>
                        <HelpRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                    {row.rejected_reason ?? ''}
                  </FlexBox>
                ) : (
                  ''
                )}
              </TableCellCustom>{' '}
              <TableCellCustom align="center" className="table_overflow-text">
                {row.rejected_reason_detail ? (
                  <FlexBox>
                    <Tooltip title={row.rejected_reason_detail}>
                      <IconButton sx={{ fontSize: 17 }}>
                        <HelpRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                    {row.rejected_reason_detail ?? ''}
                  </FlexBox>
                ) : (
                  ''
                )}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text" align="center">
                {row.total_points}
              </TableCellCustom>
              <TableCellCustom>
                {dayjs(row.created).format('MM-DD-YYYY')}
              </TableCellCustom>
              <TableCellCustom>
                {dayjs(row.modified).format('MM-DD-YYYY')}
              </TableCellCustom>
              <TableCellCustom>
                {row.found_date
                  ? dayjs(row.found_date).format('MM-DD-YYYY')
                  : ''}
              </TableCellCustom>
              <TableCellCustom>
                {row.date_approved
                  ? dayjs(row.modified).format('MM-DD-YYYY')
                  : ''}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                {row.notes}
              </TableCellCustom>
            </StyledTableRow>
          );
        })}
      </TableWrapper>
      <MainDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
        <EditClaim
          setOpenDrawer={setOpenDrawer}
          fetchInfo={fetchInfo}
          handleMessage={handleMessage}
          disabledAll={UserContext.profile.role === 'trainer'}
          claimLink={claimEdit}
        />
      </MainDrawer>
      <ClaimRejectModal
        openModal={openModalReject}
        closeModal={closeModalReject}
        linkOwner={objectClaimReject.ownerName}
        linkURL={objectClaimReject.obj.url}
        claimId={objectClaimReject.id}
        claimObject={objectClaimReject.obj}
        handleSubmit={handleSubmit}
        loading={isLoading}
      />
      <ClaimApproveModal
        openModal={openModalApprove}
        closeModal={closeModalApprove}
        linkOwner={objectClaimReject.ownerName}
        linkURL={objectClaimReject.obj.url}
        claimId={objectClaimReject.id}
        claimObject={objectClaimReject.obj}
        handleSubmit={handleSubmit}
        loading={isLoading}
      />
      {/* <Modal open={openModal}>
        <Box sx={style}>
          <>
            <Box justifyContent="end" display="flex">
              <IconButton
                onClick={() => setOpenModal(false)}
                sx={{ fontSize: 17 }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Typography
              fontWeight={700}
              fontSize={17}
              textAlign="center"
              textTransform="capitalize"
            >
              {objAppRej.status === 'rejected' ? 'Reject' : 'Approve'} Claim
            </Typography>
            <Typography marginTop={2} fontSize={14} align="center">
              Are you sure you want to{' '}
              {objAppRej.status === 'rejected' ? 'reject' : 'approve'} this
              claim?
            </Typography>
            <Box marginTop={3.5}>
              <FlexBox marginTop={3} justifyContent="center">
                <MainButton
                  onClick={() => setOpenModal(false)}
                  variant="contained"
                  sx={{
                    backgroundColor: '#F0F0F0',
                    color: 'black',
                    marginRight: 1,
                    '&:hover': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                >
                  Cancel
                </MainButton>
                <MainButton
                  variant="contained"
                  onClick={() => handleSubmit()}
                  disabled={false}
                  loading={isLoading}
                  sx={{
                    marginLeft: 1,
                    backgroundColor:
                      objAppRej.status === 'rejected' ? '#EB9393' : '#BAF372',
                    color: 'black',
                    '&:hover': {
                      backgroundColor:
                        objAppRej.status === 'rejected' ? '#EB9393' : '#BAF372',
                    },
                  }}
                >
                  {objAppRej.status === 'rejected' ? 'Reject' : 'Approve'}
                </MainButton>
              </FlexBox>
            </Box>
          </>
        </Box>
      </Modal> */}
    </>
  );
};

export default Claims;
