import { Box, IconButton, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import React from 'react';
import { MainButton } from '../buttons';
import { FlexBox } from '../tableItems';

interface Props {
  title: string;
  buttonText?: string;
  buttonFunction?: () => void;
  disabled?: boolean;
  button: boolean;
  updateFormDrawer?: () => void;
  disabledButtons?: boolean;
  filter?: boolean;
  children?: React.ReactNode;
}

const TitleButton = ({
  title,
  buttonText,
  buttonFunction,
  button,
  disabled,
  updateFormDrawer,
  disabledButtons,
  children,
  filter,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {title !== '' && (
        <Typography fontSize={24} fontWeight={900}>
          {title}
        </Typography>
      )}
      <FlexBox>
        {filter && (
          <IconButton
            aria-label="filter"
            disabled={disabledButtons}
            id="long-button"
            sx={{ border: 1, borderRadius: '10%' }}
            aria-haspopup="true"
            onClick={() => {
              if (updateFormDrawer) updateFormDrawer();
            }}
          >
            <FilterListIcon
              sx={{
                color: 'black',
                '&:hover': { color: 'green' },
              }}
            />
          </IconButton>
        )}
        {children}
      </FlexBox>
      {button && (
        <MainButton
          disabled={disabled || disabledButtons}
          variant="contained"
          sx={{
            color: 'black',
            backgroundColor: '#BAF372',
            '&:hover': {
              backgroundColor: '#BAF372',
            },
          }}
          type="submit"
          onClick={buttonFunction}
        >
          {buttonText}
        </MainButton>
      )}
    </Box>
  );
};

export default TitleButton;
