/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';
import {
  SelectFormInput,
  TagsSelect,
} from '../../../components/Forms/FormInputs';
import { BasicArray, SeverityType } from '../../../models/General';
import { FormButtons, MainButton } from '../../../components/buttons';
import {
  SelectSnooze,
  SelectTime,
  TextInputField,
} from '../../../components/inputs';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import { fetchAudiencesForForm } from '../../../helpers/audience';
import { fetchAffinitiesForForm } from '../../../helpers/affinities';
import {
  fetchTemplatesFilter,
  getTemplateTranslate,
  getTemplateTranslateEmail,
} from '../../../helpers/templates';
import CustomToolbar from './CustomToolBar';
import ScheduleSend from './ScheduleSend';
import {
  getTimeFrames,
  loadDraftForEmail,
  scheduleSendEmail,
  updateLink,
} from '../../../helpers/links';
import WarningDraft from './WarningDraft';
import useLinkForm from '../../../stores/links/useLinkForm';
import AnotherEmail from './AnotherEmail';
import PreviewEmail from './PreviewEmail';
import useSidebar from '../../../stores/links/useSidebar';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 2,
  pt: 2,
  pb: 3,
  zIndex: 1100,
};

type ContactsEmail = {
  name: string;
  id: number;
  email: string;
  default: boolean;
};

interface LinkEmailSaveProps {
  open: boolean;
  closeModal: () => void;
  site: number;
  contacts: ContactsEmail[];
  url: string;
  linkId: number;
  handleFeedbackMessage: (message: string, severity?: SeverityType) => void;
  getNotesAndHistory?: () => Promise<[void, void]>;
}

type ArrayOptions = {
  name: string;
  id: number;
};

const sanitizeQuillContent = (content: string) => {
  return content.replace(/<p><br><\/p>/g, '\n');
};

const LinkEmailSave: React.FC<LinkEmailSaveProps> = ({
  open,
  closeModal,
  site,
  contacts,
  url,
  linkId,
  handleFeedbackMessage,
  getNotesAndHistory,
}) => {
  const abortControllerAudience = useRef<AbortController | null>(null);
  const abortControllerAffinity = useRef<AbortController | null>(null);
  const abortControllerTemplate = useRef<AbortController | null>(null);

  const { updateLinkForm } = useLinkForm();
  const [loadingDraft, setLoadingDraft] = useState(false);
  const { updateSidebar } = useSidebar();

  const [openPreviewEmail, setOpenPreviewEmail] = useState(false);
  const closeModalPreview = () => setOpenPreviewEmail(false);
  const openModalPreview = () => setOpenPreviewEmail(true);

  const [draft, setDraft] = useState(false);
  const closeDraft = () => setDraft(false);
  const openDraft = () => setDraft(true);

  const [openOtherEmail, setOpenOtherEmail] = useState(false);
  const closeOtherEmail = () => setOpenOtherEmail(false);
  const openModalOtherEmail = () => setOpenOtherEmail(true);

  const quillRef = useRef<ReactQuill | null>(null);

  const [loadingSend, setLoadingSend] = useState(false);

  const cancelRequestAud = () =>
    abortControllerAudience.current && abortControllerAudience.current.abort();

  const cancelRequestAff = () =>
    abortControllerAffinity.current && abortControllerAffinity.current.abort();

  const cancelRequestTemplate = () =>
    abortControllerTemplate.current && abortControllerTemplate.current.abort();

  const [loadingAud, setLoadingAud] = useState(false);
  const [loadingAff, setLoadingAff] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const [audienceOptions, setAudienceOptions] = useState<ArrayOptions[]>([]);
  const [affinityOptions, setAffinityOptions] = useState<ArrayOptions[]>([]);
  const [templateOptions, setTemplateOptions] = useState<ArrayOptions[]>([]);

  const { UserContext } = useContext(Context) as UserContextType;
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [unsubscribeHtml, setUnsubscribeHtml] = useState('');
  const [signature, setSignature] = useState('');
  const [pixelTracker, setPixelTracker] = useState('');
  const [draftId, setDraftId] = useState(0);

  const [contactsOptions, setContactsOptions] = useState<BasicArray[]>([]);
  const [contactSelected, setContactSelected] = useState(0);
  // const [contactsSelected, setContactsSelected] = useState<BasicArray[]>([]);

  const [audienceSelected, setAudienceSelected] = useState(0);
  const [affinitySelected, setAffinitySelected] = useState(0);
  const [templateSelected, setTemplateSelected] = useState(0);

  const [loadingBody, setLoadingBody] = useState(false);

  const [scheduleSendModal, setScheduleSendModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTimeBlock, setSelectedTimeBlock] = useState('');
  const [timeFrames, setTimeFrames] = useState<
    {
      block_title: string;
      block_start: string;
      scheduled_messages: number;
      is_full: false;
    }[]
  >([]);
  const [snoozedUntil, setSnoozedUntil] = useState(7);

  const [loadAllDraft, setLoadAllDraft] = useState(false);

  const handleBodyChange = (content: string) => setBody(content);

  const getBlockTimes = async () => {
    setTimeFrames([]);
    setSelectedTimeBlock('');
    try {
      const dateNow = dayjs(selectedDate).format('YYYY-MM-DD');
      const resp = await getTimeFrames(dateNow);
      setTimeFrames(resp.blocks);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedDate === '') {
      setTimeFrames([]);
      setSelectedTimeBlock('');
    } else getBlockTimes();
  }, [selectedDate]);

  useEffect(() => {
    if (contacts.length > 0) {
      const dateToday = dayjs().format('MM/DD/YYYY');
      setSelectedDate(dateToday);
      setContactsOptions(
        contacts.map(item => {
          return { id: item.id, name: item.email };
        }),
      );
      const defaultUser = contacts.find(item => item.default);
      if (defaultUser) setContactSelected(defaultUser.id);
      // setContactsSelected([{ id: defaultUser.id, name: defaultUser.email }]);
    } else {
      setContactsOptions([]);
      setContactSelected(0);
    }

    return () => {
      setContactsOptions([]);
      setContactSelected(0);
    };
  }, [contacts]);

  const transformContent = (content: string) => {
    return content.replace(/\n/g, '<br>');
  };
  // const getObjects = () => {
  //   const findContacts = contacts.filter(contact =>
  //     contactsSelected.some(selected => selected.id === contact.id),
  //   );
  //   return findContacts;
  // };

  const getBodyTemplate = async () => {
    setLoadingBody(true);
    try {
      const fullContact = contacts.find(item => item.id === contactSelected);
      if (fullContact) {
        const resp = await getTemplateTranslateEmail(
          templateSelected,
          encodeURIComponent(url),
          linkId,
          encodeURIComponent(fullContact.name),
          encodeURIComponent(fullContact.email),
        );
        setBody(transformContent(`${resp.body}`));
        setUnsubscribeHtml(resp.unsubscribe_html);
        setSignature(resp.signature_html);
        setPixelTracker(resp.pixel_tracker_url);
        setSubject(resp.subject);
      } else {
        setBody('');
        setSubject('');
      }
    } catch (err) {
      setBody('');
    } finally {
      setLoadingBody(false);
    }
  };

  useEffect(() => {
    if (templateSelected !== 0 && !loadAllDraft) getBodyTemplate();
  }, [templateSelected]);

  const getTemplates = async () => {
    setLoadingTemplates(true);
    setTemplateSelected(0);
    setTemplateOptions([]);
    cancelRequestTemplate();
    try {
      const resp = await fetchTemplatesFilter(
        audienceSelected,
        affinitySelected,
        site,
      );

      const tempsFilter = resp.filter(template => template.status === 'active');

      setTemplateOptions(
        tempsFilter.map(item => {
          return { id: item.id, name: item.title };
        }),
      );
    } catch (err) {
      setTemplateOptions([]);
    } finally {
      setLoadingTemplates(false);
    }
  };

  useEffect(() => {
    if (affinitySelected !== 0 && !loadAllDraft) getTemplates();
  }, [affinitySelected]);

  const getAffinities = async () => {
    setLoadingAff(true);
    setAffinitySelected(0);
    setTemplateSelected(0);
    setAffinityOptions([]);
    setTemplateOptions([]);
    cancelRequestAff();
    cancelRequestTemplate();

    abortControllerAffinity.current = new AbortController();
    try {
      const resp = await fetchAffinitiesForForm(
        audienceSelected,
        site,
        abortControllerAffinity.current.signal,
      );
      setAffinityOptions(resp);
    } catch (err) {
      setAffinityOptions([]);
    } finally {
      setLoadingAff(false);
    }
  };

  useEffect(() => {
    if (audienceSelected !== 0 && !loadAllDraft) getAffinities();
  }, [audienceSelected]);

  const getAudiences = async () => {
    setLoadingAud(true);
    if (!loadAllDraft) {
      setAudienceOptions([]);
      setAffinityOptions([]);
      setTemplateOptions([]);
      cancelRequestAud();
      cancelRequestAff();
    }
    if (site === 0) {
      setLoadingAud(false);
      return;
    }

    abortControllerAudience.current = new AbortController();
    try {
      const resp = await fetchAudiencesForForm(
        site,
        abortControllerAudience.current.signal,
      );
      setAudienceOptions(resp);
    } catch (err) {
      setAudienceOptions([]);
    } finally {
      setLoadingAud(false);
    }
  };

  useEffect(() => {
    getAudiences();

    return () => {
      setAudienceOptions([]);
      setAffinityOptions([]);
      setTemplateOptions([]);
    };
  }, [site]);

  useEffect(() => {
    return () => {
      setAudienceOptions([]);
      setAffinityOptions([]);
      setTemplateOptions([]);
    };
  }, []);

  const handleUndo = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.history.undo();
    }
  };

  const handleRedo = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.history.redo();
    }
  };

  const customHandlers = {
    undo: () => {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        editor.history.undo();
      }
    },
    redo: () => {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        editor.history.redo();
      }
    },
  };

  const transformTimeBlockTo24Hour = (timeBlock: string) => {
    const [startTime] = timeBlock.split(' - ');

    const parsedTime = dayjs(startTime, 'h:mm A');

    return parsedTime.format('HH:mm:ss');
  };

  const handleSend = async () => {
    setLoadingSend(true);
    const rawContent = quillRef.current?.getEditor().root.innerHTML || '';
    const sanitizedContent = sanitizeQuillContent(rawContent);
    const object: any = {
      link_id: linkId,
      contact_id: contactSelected,
      audience_id: audienceSelected,
      affinity_id: affinitySelected,
      template_id: templateSelected,
      site_id: site,
      is_sender_user: true,
      body: sanitizedContent,
      subject,
      signature_html: signature,
      unsubscribe_html: unsubscribeHtml,
      pixel_tracker_url: pixelTracker,
    };
    if (draftId !== 0) object.draft_id = draftId;
    const dateTransformed = dayjs(selectedDate).format('YYYY-MM-DD');
    if (selectedDate) {
      // Direct send doesnt have a block selection
      const currentHour = dayjs().format('HH:mm:ss');
      object.scheduled_date = selectedTimeBlock
        ? `${dateTransformed} ${selectedTimeBlock}`
        : `${dateTransformed} ${currentHour}`;
    }
    if (snoozedUntil) object.snoozed_days = snoozedUntil;

    try {
      await scheduleSendEmail(object);
      handleFeedbackMessage(
        `Email ${
          selectedTimeBlock ? 'scheduled succesfully' : 'sent succesfully'
        }`,
        'success',
      );
      setScheduleSendModal(false);
      updateSidebar({ type: 'read' });
      closeModal();
      closeDraft();
      closeModal();
      closeOtherEmail();
      closeDraft();
      setScheduleSendModal(false);
      setBody('');
      setSubject('');
      setSelectedDate('');
      setContactSelected(0);
      setSelectedTimeBlock('');
      setSnoozedUntil(7);
      setAudienceSelected(0);
      setAffinitySelected(0);
      setTemplateSelected(0);
      setDraftId(0);
      setAffinityOptions([]);
      setTemplateOptions([]);
      setSignature('');
      setPixelTracker('');
      setUnsubscribeHtml('');
      if (getNotesAndHistory) getNotesAndHistory();
    } catch (err) {
      handleFeedbackMessage('Error sending email', 'error');
    } finally {
      setLoadingSend(false);
    }
  };

  const handleDraft = async () => {
    const rawContent = quillRef.current?.getEditor().root.innerHTML || '';
    const sanitizedContent = sanitizeQuillContent(rawContent);

    setLoadingDraft(true);
    const object: any = {
      link_id: linkId,
      contact_id: contactSelected,
      audience_id: audienceSelected,
      affinity_id: affinitySelected,
      template_id: templateSelected,
      site_id: site,
      is_sender_user: true,
      body: sanitizedContent,
      subject,
      signature_html: signature,
      unsubscribe_html: unsubscribeHtml,
      pixel_tracker_url: pixelTracker,
    };
    const dateTransformed = dayjs(selectedDate).format('YYYY-MM-DD');
    if (selectedDate) {
      // Drafts doesn't have a block selection
      const currentHour = dayjs().format('HH:mm:ss');
      object.scheduled_date = selectedTimeBlock
        ? `${dateTransformed} ${selectedTimeBlock}`
        : `${dateTransformed} ${currentHour}`;
    }
    if (snoozedUntil) object.snoozed_days = snoozedUntil;

    object.send_status = 'draft';
    try {
      await scheduleSendEmail(object);
      handleFeedbackMessage('Draft created succesfully', 'success');
      closeModal();
      closeDraft();
      setScheduleSendModal(false);
      setBody('');
      setSubject('');
      setSelectedDate('');
      setSelectedTimeBlock('');
      setSnoozedUntil(7);
      setAudienceSelected(0);
      setDraftId(0);
      setAffinitySelected(0);
      setContactSelected(0);
      setSignature('');
      setPixelTracker('');
      setUnsubscribeHtml('');
      setTemplateSelected(0);
      updateLinkForm({
        affinity: affinitySelected,
        audience: audienceSelected,
        template: templateSelected,
      });
    } catch (err) {
      handleFeedbackMessage('Error while creating draft', 'error');
    } finally {
      setLoadingDraft(false);
    }
  };

  const fetchDraftAud = async () => {
    setLoadingAud(true);

    abortControllerAudience.current = new AbortController();
    try {
      const resp = await fetchAudiencesForForm(
        site,
        abortControllerAudience.current.signal,
      );
      setAudienceOptions(resp);
    } catch (err) {
      setAudienceOptions([]);
    } finally {
      setLoadingAud(false);
    }
  };

  const fetchDraftAff = async (audId: number) => {
    setLoadingAff(true);

    abortControllerAffinity.current = new AbortController();
    try {
      const resp = await fetchAffinitiesForForm(
        audId,
        site,
        abortControllerAffinity.current.signal,
      );
      setAffinityOptions(resp);
    } catch (err) {
      setAffinityOptions([]);
    } finally {
      setLoadingAff(false);
    }
  };

  const fetchDraftTemp = async (audId: number, affId: number) => {
    setLoadingTemplates(true);

    try {
      const resp = await fetchTemplatesFilter(audId, affId, site);

      const tempsFilter = resp.filter(template => template.status === 'active');

      setTemplateOptions(
        tempsFilter.map(item => {
          return { id: item.id, name: item.title };
        }),
      );
    } catch (err) {
      setTemplateOptions([]);
    } finally {
      setLoadingTemplates(false);
    }
  };

  const fetchDraftInformation = async () => {
    setLoadAllDraft(true);

    try {
      const resp = await loadDraftForEmail(contactSelected, linkId, site);
      if (resp.site_id === site) {
        setAudienceSelected(resp.audience_id);
        setAffinitySelected(resp.affinity_id);
        setTemplateSelected(resp.template_id);
        await Promise.all([
          fetchDraftAff(resp.audience_id),
          fetchDraftTemp(resp.audience_id, resp.affinity_id),
          fetchDraftAud(),
        ]);
        setBody(transformContent(resp.email_body));
        setSubject(resp.email_subject);
        setDraftId(resp.id);
        setUnsubscribeHtml(resp.unsubscribe_html);
        setSignature(resp.signature_html);
        setPixelTracker(resp.pixel_tracker_url);
      } else {
        setDraftId(0);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadAllDraft(false);
    }
  };

  useEffect(() => {
    if (contactSelected !== 0 && linkId !== 0 && !loadAllDraft) {
      fetchDraftInformation();
    }
  }, [contactSelected]);

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableEnforceFocus
      disableEscapeKeyDown
      disableRestoreFocus
    >
      <Box sx={style}>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: 8000,
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          open={loadAllDraft}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <FlexBoxBetween>
          <Typography fontWeight={600} fontSize={17} textTransform="capitalize">
            Initial Outreach Attempt
          </Typography>
          <IconButton
            onClick={() => {
              if (body.trim() === '') {
                closeModal();
                closeDraft();
                closeModal();
                closeDraft();
                setScheduleSendModal(false);
                setBody('');
                setSubject('');
                setSelectedDate('');
                setSelectedTimeBlock('');
                setSnoozedUntil(7);
                setAudienceSelected(0);
                setSignature('');
                setPixelTracker('');
                setUnsubscribeHtml('');
                setAffinitySelected(0);
                setContactSelected(0);
                setTemplateSelected(0);
                setAffinityOptions([]);
                setTemplateOptions([]);
                setDraftId(0);
              } else openDraft();
            }}
            sx={{ fontSize: 22, border: 1 }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </FlexBoxBetween>
        <Divider sx={{ mb: 2, mt: 1 }} />

        <FlexBox gap={1}>
          <Typography fontSize={15} fontWeight={600}>
            Opportunity:
          </Typography>
          <Link
            href={url}
            target="_blank"
            color="#373737"
            underline="hover"
            fontSize={14}
          >
            {url}
          </Link>
        </FlexBox>

        <Box my={2}>
          <FlexBox>
            <Typography fontSize={16} mr={1}>
              From:
            </Typography>
            <Chip label={UserContext.email} variant="outlined" />
          </FlexBox>
          <FlexBox mt={2} gap={1}>
            <Typography fontSize={16} mr={1}>
              To:
            </Typography>
            <FormControl fullWidth>
              <SelectFormInput
                loading={false}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={contactsOptions}
                disabled={contactsOptions.length === 0}
                placeholder=""
                value={
                  contactsOptions.find(item => item.id === contactSelected) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setContactSelected(value ? (value.id as number) : 0)
                }
              />
              {/* <TagsSelect
                value={contactsSelected}
                options={contactsOptions}
                disabled={false}
                placeholder="Contacts"
                required
                limitTags={3}
                hideLabel
                updateSelect={(value: BasicArray[]) => {
                  setContactsSelected(value);
                }}
              /> */}
            </FormControl>
            {/* {contactsList.map((contact, index) => (
              <>
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  label={contact}
                  variant="outlined"
                  onDelete={
                    contactsList.length === 1
                      ? undefined
                      : () => handleDeleteContact(contact)
                  }
                />
              </>
            ))} */}
          </FlexBox>

          <FlexBox mt={2.5} pr={2}>
            <Typography fontSize={16} mr={1}>
              Subject
            </Typography>
            <TextInputField
              value={subject}
              name="subject"
              disabled={false}
              error={false}
              onChange={e => setSubject(e.target.value)}
              size="small"
              placeholder="Subject"
              id="url"
              helperText={false}
              fullWidth
            />
          </FlexBox>
          <Grid container spacing={2} width="100%" mt={1}>
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingAud}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={audienceOptions}
                disabled={loadingAud || audienceOptions.length === 0}
                placeholder="Audience"
                value={
                  audienceOptions.find(
                    item => item.id === audienceSelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setAudienceSelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>{' '}
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingAff}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={affinityOptions}
                disabled={loadingAff || affinityOptions.length === 0}
                placeholder="Affinity"
                value={
                  affinityOptions.find(
                    item => item.id === affinitySelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setAffinitySelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>{' '}
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingTemplates}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={templateOptions}
                disabled={templateOptions.length === 0 || loadingTemplates}
                placeholder="Template"
                value={
                  templateOptions.find(
                    item => item.id === templateSelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setTemplateSelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <CustomToolbar onRedo={handleRedo} onUndo={handleUndo} />
            <ReactQuill
              theme="snow"
              style={{ minHeight: '200px', fontSize: '13px' }}
              className="my-custom-editor"
              readOnly={loadingBody}
              ref={quillRef}
              value={body}
              onChange={handleBodyChange}
              placeholder="Compose your message..."
              modules={{
                toolbar: {
                  container: '#custom-toolbar',
                  // handlers: customHandlers,
                },

                history: {
                  delay: 1000,
                  maxStack: 50,
                  userOnly: true,
                },
              }}
            />
          </Box>
          <MainButton
            variant="contained"
            onClick={openModalPreview}
            disabled={body === ''}
            loading={false}
            sx={{ mt: 2 }}
            startIcon={<RemoveRedEyeIcon />}
          >
            Preview
          </MainButton>
          {/* <FlexBox mt={2} gap={2}>
            <Typography>Snooze for </Typography>
            <Select
              value={snoozedUntil}
              name="bulk"
              id="bulk"
              disabled={false}
              displayEmpty
              onChange={e => setSnoozedUntil(Number(e.target.value))}
              sx={{ fontSize: 12 }}
              IconComponent={KeyboardArrowDownIcon}
              size="small"
              input={<SelectSnooze />}
            >
              <MenuItem value={1}>1 days</MenuItem>
              <MenuItem value={2}>2 days</MenuItem>
              <MenuItem value={3}>3 days</MenuItem>
              <MenuItem value={4}>4 days</MenuItem>
              <MenuItem value={5}>5 days</MenuItem>
              <MenuItem value={6}>6 days</MenuItem>
              <MenuItem value={7}>7 days (Default)</MenuItem>
            </Select>
          </FlexBox> */}
          <FlexBox mt={3} gap={2}>
            <FormButtons
              variant="contained"
              disabled={
                audienceSelected === 0 ||
                affinitySelected === 0 ||
                templateSelected === 0 ||
                body === '' ||
                subject === ''
              }
              loading={loadingSend}
              sx={{
                backgroundColor: '#84D0F0',
                py: '5px',
                color: 'black',
                '&:hover': {
                  backgroundColor: '#84D0F0',
                },
              }}
              onClick={() => setScheduleSendModal(true)}
            >
              Schedule Send
            </FormButtons>
            <FormButtons
              loading={loadingSend}
              variant="contained"
              sx={{
                background: '#BAF372',
                padding: '5px 20px',

                color: 'black',
                '&:hover': {
                  backgroundColor: '#BAF372',
                },
              }}
              disabled={
                audienceSelected === 0 ||
                affinitySelected === 0 ||
                templateSelected === 0 ||
                body === '' ||
                subject === ''
              }
              onClick={handleSend}
            >
              Send
            </FormButtons>
          </FlexBox>
        </Box>
        <WarningDraft
          open={draft}
          closeFunc={() => {
            closeModal();
            closeDraft();
            closeModal();
            closeDraft();
            setScheduleSendModal(false);
            setBody('');
            setSubject('');
            setSelectedDate('');
            setSelectedTimeBlock('');
            setSnoozedUntil(7);
            setAudienceSelected(0);
            setSignature('');
            setPixelTracker('');
            setDraftId(0);
            setUnsubscribeHtml('');
            setContactSelected(0);
            setAffinitySelected(0);
            setTemplateSelected(0);
            setAffinityOptions([]);
            setTemplateOptions([]);
          }}
          closeModal={closeDraft}
          loadingDraft={loadingDraft}
          continueModal={handleDraft}
        />
        {/* <AnotherEmail
          open={openOtherEmail}
          closeFunc={() => {
            closeModal();
            closeDraft();
            closeModal();
            closeOtherEmail();
            closeDraft();
            setScheduleSendModal(false);
            setBody('');
            setSubject('');
            setSelectedDate('');
            setContactSelected(0);
            setSelectedTimeBlock('');
            setSnoozedUntil(7);
            setAudienceSelected(0);
            setAffinitySelected(0);
            setTemplateSelected(0);
            setAffinityOptions([]);
            setTemplateOptions([]);
          }}
          continueModal={closeOtherEmail}
          closeModal={closeOtherEmail}
        /> */}
        <ScheduleSend
          open={scheduleSendModal}
          send={handleSend}
          loading={loadingSend}
          timeFrames={timeFrames}
          closeModal={(value: boolean) => setScheduleSendModal(value)}
          selectedDate={selectedDate}
          selectedTimeBlock={selectedTimeBlock}
          updateSelectedTime={(value: string) => setSelectedTimeBlock(value)}
          updateSelectedDate={(value: string) => setSelectedDate(value)}
        />
        <PreviewEmail
          open={openPreviewEmail}
          closeModal={closeModalPreview}
          quillRef={quillRef}
          body={`${body}<br>${signature}<br>${unsubscribeHtml}`}
        />
      </Box>
    </Modal>
  );
};

export default LinkEmailSave;
