import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { FlexBox } from '../../../components/tableItems';
import PaginateText from './PaginateText';

interface NewPaginationProps {
  pageSize: number;
  countItems: number;
  page: number;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (value: number) => void;
}

const NewPagination: React.FC<NewPaginationProps> = ({
  pageSize,
  page,
  countItems,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const lastPage = Math.ceil(countItems / pageSize);

  return (
    <>
      {countItems > 0 && (
        <FlexBox>
          <PaginateText
            text="&larr; Prev"
            condition={0}
            page={page}
            onClick={() => {
              if (page - 1 === 0) return;
              handleChangePage(page - 1);
            }}
          />

          <FlexBox>
            <Select
              value={pageSize}
              onChange={e => handleChangeRowsPerPage(e.target.value as number)}
              sx={{
                '& .MuiInputBase-input': {
                  border: 'none',
                  outline: 'none',
                  padding: '3px 8px',
                  fontSize: 12,
                  color: '#373737',

                  '@media (min-width: 1900px)': {
                    fontSize: '15px',
                    padding: '5px 15px',
                  },
                },

                marginX: 1,
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={300}>300</MenuItem>
            </Select>
          </FlexBox>

          <PaginateText
            text="Next &rarr;"
            condition={lastPage - 1}
            page={page}
            onClick={() => {
              if (page - 1 === lastPage - 1) return;
              handleChangePage(page + 1);
            }}
          />
        </FlexBox>
      )}
    </>
  );
};

export default NewPagination;
