/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Divider,
  FormControl,
  RadioGroup,
  IconButton,
  Autocomplete,
  TextField,
  CircularProgress,
  InputAdornment,
  FormControlLabel,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  TextareaAutosize,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  CustomRadio,
  CustomTextAreaField,
  DefaultCheckbox,
  FormWithLabel,
  SelectInput,
  TextInputField,
  TextInputFieldError,
  TextInputPendingOutreach,
} from '../inputs';
import { LinkStatuses } from '../../models/Status';
import { Owners } from '../../models/User';
import { FlexBox, FlexBoxBetween } from '../tableItems';
import { SitesRequestV2 } from '../../models/Sites';
import { TemplatesRequest } from '../../models/Templates';
import { BasicArray } from '../../models/General';
import { ContactsFormLink, ContactsLatestEvent } from '../../models/Forms';
import { ContactsValuesType } from '../../helpers/contacts';
import { isURL } from '../../helpers/validateInputs';
import { MainButton } from '../buttons';
import { IS_USER_ADMIN } from '../../constants/user';

interface PropsTextField {
  nameInput: string;
  error: boolean;
  errorText: string;
  disabled: boolean;
  required?: boolean;
  label: string;
  multiline?: boolean;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  onBlurValidate?: (e?: string) => void;
  onFocus?: () => void;
  type?: string;
  rows?: number;
  autocomplete?: string;
  minRows?: number;
}

const CustomTextArea = styled(TextField)({
  '& .MuiInputBase-inputMultiline': {
    resize: 'vertical',
  },
});

export const TextFormField = ({
  nameInput,
  error,
  errorText,
  placeholder,
  value,
  label,
  required,
  disabled,
  onChange,
  multiline,
  onBlurValidate,
  onFocus,
  type,
  onBlur,
  rows,
  autocomplete,
  minRows,
}: PropsTextField) => {
  return (
    <>
      <InputLabel
        htmlFor={nameInput}
        sx={{
          fontSize: 12,
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
          marginBottom: 1,
        }}
      >
        {label}{' '}
        {required && label !== '' && (
          <span className="required-fields_dot">*</span>
        )}
      </InputLabel>

      <TextInputField
        value={value}
        name={nameInput}
        disabled={disabled}
        error={error}
        sx={{
          '& .MuiInputBase-inputMultiline': {
            resize: 'vertical',
          },
        }}
        size="small"
        rows={multiline && value.length > 30 ? 20 : 4}
        autoComplete={autocomplete}
        onFocus={onFocus}
        onBlur={e => {
          if (onBlur) onBlur(e.target.value);
          if (onBlurValidate) onBlurValidate();
        }}
        multiline={multiline}
        onChange={e => onChange(e.target.value)}
        fullWidth
        type={type ?? 'text'}
        placeholder={placeholder}
        id={nameInput}
        helperText={errorText}
      />
    </>
  );
};

interface StatusSelectLinkFormFieldProps {
  label: string;
  nameInput: string;
  value: string;
  error: boolean;
  required?: boolean;
  options: LinkStatuses[];
  disabled: boolean;
  loading?: boolean;
  onChangeFunction: (value: string) => void;
}

export const StatusSelectLinkFormField = ({
  label,
  nameInput,
  value,
  error,
  required,
  options,
  disabled,
  loading,
  onChangeFunction,
}: StatusSelectLinkFormFieldProps) => (
  <>
    <InputLabel
      htmlFor={nameInput}
      sx={{
        fontSize: 12,
        fontWeight: '700',
        color: 'rgba(0, 0, 0, 0.8)',
        marginBottom: 1,
      }}
    >
      {label} {required && <span className="required-fields_dot">*</span>}
    </InputLabel>
    <Select
      value={value}
      name={nameInput}
      id={nameInput}
      size="small"
      disabled={disabled || loading}
      displayEmpty
      fullWidth
      renderValue={selected => {
        if (loading) {
          return <Box>Loading...</Box>;
        }
        if (selected !== '') {
          return (
            <FlexBox>
              <div className="status-select-forms" />
              {options.find(item => item.value === selected)?.name}
            </FlexBox>
          );
        }
        return 'None';
      }}
      onChange={e => onChangeFunction(e.target.value)}
      sx={{ fontSize: 12 }}
      placeholder={loading ? 'Loading...' : label}
      className={error ? 'select-error' : ''}
      IconComponent={KeyboardArrowDownIcon}
      input={<SelectInput />}
    >
      <MenuItem value="" disabled>
        None
      </MenuItem>
      {options.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  </>
);

interface OwnersSelectLinkFormFieldProps {
  label: string;
  nameInput: string;
  value: number;
  errorText: string;
  error: boolean;
  required?: boolean;
  disabled: boolean;
  loading?: boolean;
  options: Owners[];
  onChangeFunction: (value: number) => void;
}

export const OwnersSelectLinkFormField = ({
  label,
  nameInput,
  value,
  error,
  errorText,
  required,
  disabled,
  loading,
  options,
  onChangeFunction,
}: OwnersSelectLinkFormFieldProps) => (
  <>
    <InputLabel
      htmlFor={nameInput}
      sx={{
        fontSize: 12,
        fontWeight: '700',
        color: 'rgba(0, 0, 0, 0.8)',
        marginBottom: 1,
      }}
    >
      {label} {required && <span className="required-fields_dot">*</span>}
    </InputLabel>

    <Select
      value={value}
      name={nameInput}
      id={nameInput}
      displayEmpty
      renderValue={selected => {
        if (loading) {
          return <Box>Loading...</Box>;
        }
        if (selected !== 0) {
          return (
            <FlexBox>
              <div className="user-select-forms" />
              {options.find(item => item.id === selected)?.username}
            </FlexBox>
          );
        }
        return 'None';
      }}
      fullWidth
      disabled={disabled || loading}
      placeholder="Owners"
      sx={{ fontSize: 14 }}
      onChange={e => onChangeFunction(e.target.value as number)}
      className={error ? 'select-error' : ''}
      IconComponent={KeyboardArrowDownIcon}
      input={<SelectInput />}
    >
      <MenuItem value={0} disabled>
        None
      </MenuItem>
      {options.map(item => (
        <MenuItem key={item.id} value={item.id}>
          {item.username}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText error={error} sx={{ marginLeft: '14px' }}>
      {errorText}
    </FormHelperText>
  </>
);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface ContactFormProps {
  index: number;
  removeContact: (index: number) => void;
  disableButtonAdd: boolean;
  contact: ContactsFormLink;
  contactValidation: ContactsValuesType;
  site: number;
  disableEditButton: boolean;
  handleEmailChange: (value: string, arrId: number) => Promise<void>;
  updateField: (value: string, arrId: number, field: string) => void;
  updateIsForm: (value: boolean, arrId: number) => void;
  contactsLength: number;
  updateIsDefault: (value: boolean, arrId: number) => void;
  is_loading: boolean;
  validationStatus?: string;
  isAdmin: boolean;
  doNotDelete: boolean;
  updateValidURL: (isValid: boolean, arrId: number) => void;
  handleReadMoreNote: (contactName: string, note: string) => void;
  updateNotes: (arrId: number) => void;
  addTextNote: (message: string) => void;
  template_id: number;
}

const contactStatus = [
  { id: 1, name: 'Open', value: 'open' },
  { id: 2, name: 'Global Closed', value: 'global_closed' },
  { id: 3, name: 'Project Closed', value: 'project_closed' },
  { id: 4, name: 'Site Closed', value: 'site_closed' },
  { id: 5, name: 'Invalid Contact', value: 'invalid_contact' },
];

export const ContactForms = ({
  index,
  removeContact,
  contact,
  template_id,
  contactValidation,
  site,
  disableEditButton,
  handleEmailChange,
  updateField,
  updateIsForm,
  disableButtonAdd,
  contactsLength,
  updateIsDefault,
  is_loading,
  validationStatus,
  isAdmin,
  doNotDelete,
  updateValidURL,
  handleReadMoreNote,
  updateNotes,
  addTextNote,
}: ContactFormProps) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const isLowerThan90Days = (day: string) => {
    const diffMilSeconds = Math.abs(+new Date(day) - +new Date());
    const diffDays = Math.ceil(diffMilSeconds / (1000 * 60 * 60 * 24));
    return diffDays < 90;
  };

  const closeTypes = [
    'site_closed',
    'project_closed',
    'global_closed',
    'invalid_contact',
  ];

  const tooltipInformation = () => {
    const statusContact = contact.status ?? '';
    return (
      <>
        <Box>
          {statusContact === closeTypes[0] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Site Close: No one from this site should reach out to this
                contact again, but other sites have the ability to.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `Your content is out of date.`
              </Typography>
            </>
          )}{' '}
          {statusContact === closeTypes[1] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Project Close: No one from this project should reach out to this
                contact again, but other projects have the ability to.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                *i.e If a contact is marked as a Project Close on
                ConsumerNotice, no one from any of the Mass Torts projects will
                be able to reach out to them.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `We can only share information from government
                agencies or non-profits.`
              </Typography>
            </>
          )}
          {statusContact === closeTypes[2] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Global Close: No one from Launch That Linkbuilding should reach
                out again.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `If you email us again, we will report you for
                harassment and pursue legal action.`
              </Typography>
            </>
          )}
          {statusContact === closeTypes[3] && (
            <>
              <Typography fontSize={13} fontWeight={500}>
                Invalid Contact: The contacts for this entry are not valid.
              </Typography>
              <Typography fontSize={13} fontWeight={400}>
                Examples include: `An “Email Bounced” error message.“XYZ no
                longer works at this company.”“I am not responsible for this,
                XYZ is the director of this program.”`
              </Typography>
            </>
          )}
        </Box>
      </>
    );
  };
  const isDisabled = isAdmin
    ? is_loading || contactsLength < 2
    : disableEditButton ||
      contactsLength < 2 ||
      validationStatus === 'inactive' ||
      validationStatus === 'active' ||
      is_loading;

  const returnTime = (date: string) =>
    `${dayjs(date).format('MM/DD/YYYY')} - ${dayjs(date)
      .tz('America/New_York')
      .format('h:mma')} EST`;

  const getOutReachValue = (latest_event: ContactsLatestEvent | null) => {
    if (latest_event === null || latest_event === undefined)
      return { value: 'Pending Outreach', color: 'D7D7D7' };

    return latest_event.open_event !== null
      ? {
          value: `Open on ${returnTime(latest_event.modified)}`,
          color: 'BAF372',
        }
      : {
          value: `Sent on ${returnTime(latest_event.created)}`,
          color: 'D7D7D7',
        };
  };

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        border: '1px solid #D9D9D9',
        borderRadius: '6px',
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          padding: '5px 15px 2px 15px',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography fontSize={13} fontWeight={600} color="rgba(0, 0, 0, 0.8)">
          Contact {index + 1}
        </Typography>
        <IconButton
          size="small"
          disabled={
            isAdmin
              ? is_loading || contactsLength < 2
              : disableEditButton ||
                contactsLength < 2 ||
                validationStatus === 'inactive' ||
                validationStatus === 'active' ||
                is_loading ||
                doNotDelete
          }
          onClick={() => removeContact(index)}
        >
          <DeleteIcon
            fontSize="small"
            sx={{
              color:
                isDisabled || doNotDelete ? 'rgba(0, 0, 0, 0.5)' : '#EB9393',
            }}
          />
        </IconButton>
      </Box>

      <Divider />
      <Box sx={{ padding: '5px 12px', marginBottom: 2.8 }}>
        <FlexBoxBetween>
          <FormControl sx={{ marginBottom: 0.5 }}>
            <RadioGroup
              value={contact.is_form}
              onChange={e => updateIsForm(e.target.value === 'true', index)}
              row
            >
              <FormWithLabel
                value
                control={
                  <CustomRadio
                    size="small"
                    disabled={
                      disableEditButton ||
                      validationStatus === 'inactive' ||
                      validationStatus === 'active' ||
                      contactValidation.loading
                    }
                  />
                }
                label="Form"
              />
              <FormWithLabel
                value={false}
                control={
                  <CustomRadio
                    size="small"
                    disabled={
                      disableEditButton ||
                      validationStatus === 'inactive' ||
                      validationStatus === 'active' ||
                      contactValidation.loading
                    }
                  />
                }
                label="Email"
              />
            </RadioGroup>
          </FormControl>
          {validationStatus === 'inactive' && !contact.is_form && (
            <Typography fontSize={13} fontWeight={600}>
              Contact is Inactive
            </Typography>
          )}
          {!contact.is_form && (
            <FormControlLabel
              label="Default"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: 12,
                  color: '#000000',
                },
              }}
              control={
                <DefaultCheckbox
                  size="small"
                  disabled={
                    disableEditButton ||
                    validationStatus === 'inactive' ||
                    contactValidation.loading ||
                    !contactValidation.validEmail
                  }
                  checked={contact.is_default ?? false}
                  onChange={e => updateIsDefault(e.target.checked, index)}
                />
              }
            />
          )}
        </FlexBoxBetween>
        <div>
          {!contact.is_form && (
            <>
              <div>
                <TextFormField
                  nameInput={`name-${index}`}
                  disabled={
                    isAdmin
                      ? false
                      : disableEditButton || validationStatus === 'inactive'
                  }
                  label="Name"
                  error={false}
                  value={contact.name}
                  onChange={(value: string) =>
                    updateField(value, index, 'name')
                  }
                  errorText=""
                  placeholder="Name"
                />
              </div>
              <div>
                <InputLabel
                  htmlFor={`email-${index}`}
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                    marginBottom: 1,
                  }}
                >
                  Email <span className="required-fields_dot">*</span>
                </InputLabel>
                <TextInputField
                  value={contact.email}
                  name={`email-${index}`}
                  disabled={
                    disableEditButton ||
                    site === 0 ||
                    disableButtonAdd ||
                    validationStatus === 'inactive' ||
                    validationStatus === 'active'
                  }
                  helperText={contactValidation.message}
                  error={contactValidation.validEmail === false}
                  size="small"
                  onBlur={e => handleEmailChange(e.target.value.trim(), index)}
                  onChange={e =>
                    updateField(e.target.value.trim(), index, 'email')
                  }
                  fullWidth
                  placeholder="Email"
                  id={`email-${index}`}
                  InputProps={
                    contactValidation.loading
                      ? {
                          endAdornment: (
                            <InputAdornment position="start">
                              <CircularProgress
                                sx={{ color: '#ABABAB' }}
                                size={16}
                              />
                            </InputAdornment>
                          ),
                        }
                      : {
                          endAdornment: (
                            <InputAdornment position="end">
                              {!contact.is_form && (
                                <>
                                  {contactValidation.validEmail === true && (
                                    <CheckCircleIcon fontSize="inherit" />
                                  )}
                                  {contactValidation.validEmail ===
                                    'unknown' && (
                                    <HelpIcon fontSize="inherit" />
                                  )}
                                  {contactValidation.validEmail === false && (
                                    <CancelIcon fontSize="inherit" />
                                  )}
                                </>
                              )}
                            </InputAdornment>
                          ),
                        }
                  }
                />
              </div>
              <div>
                <FlexBox>
                  <Box sx={{ flexGrow: 1 }}>
                    <InputLabel
                      htmlFor={`status-contact-${index}`}
                      sx={{
                        fontSize: 12,
                        fontWeight: '700',
                        color: 'rgba(0, 0, 0, 0.8)',
                        marginTop: contactValidation.message !== '' ? 1 : 2,
                        marginBottom: 1,
                      }}
                    >
                      Contact Status{' '}
                      <span className="required-fields_dot">*</span>
                    </InputLabel>
                    <Select
                      value={contact.status ?? ''}
                      name={`status-contact-${index}`}
                      id={`status-contact-${index}`}
                      disabled={!isAdmin}
                      displayEmpty
                      fullWidth
                      onChange={e =>
                        updateField(e.target.value, index, 'status')
                      }
                      sx={{ fontSize: 14 }}
                      IconComponent={KeyboardArrowDownIcon}
                      input={<SelectInput />}
                    >
                      <MenuItem value="" disabled>
                        None
                      </MenuItem>
                      {contactStatus.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  {closeTypes.includes(contact.status ?? '') && (
                    <HtmlTooltip title={tooltipInformation()}>
                      <IconButton sx={{ fontSize: 16 }}>
                        <HelpRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </HtmlTooltip>
                  )}
                </FlexBox>
              </div>
              {contact.close_reason &&
                contact.close_reason !== '' &&
                contact.status !== 'open' && (
                  <Box marginTop={2}>
                    <FlexBox>
                      <Box flexGrow={1}>
                        <TextFormField
                          nameInput={`close-reason-${index}`}
                          disabled
                          label="Close Reason"
                          error={false}
                          value={contact.close_reason}
                          onChange={(value: string) =>
                            updateField(value, index, 'name')
                          }
                          errorText=""
                          placeholder="Name"
                        />
                      </Box>
                      {contact.close_reason.length > 60 && (
                        <HtmlTooltip title={contact.close_reason}>
                          <IconButton sx={{ fontSize: 16 }}>
                            <HelpRoundedIcon fontSize="inherit" />
                          </IconButton>
                        </HtmlTooltip>
                      )}
                    </FlexBox>
                  </Box>
                )}

              {contact.latest_event !== undefined && isAdmin && (
                <Box marginTop={1}>
                  <InputLabel
                    htmlFor={`latest_event-${index}`}
                    sx={{
                      fontSize: 12,
                      fontWeight: '700',
                      color: 'rgba(0, 0, 0, 0.8)',
                      marginBottom: 1,
                    }}
                  >
                    Outreach Status
                  </InputLabel>

                  <TextInputPendingOutreach
                    value={getOutReachValue(contact.latest_event).value}
                    name={`latest_event-${index}`}
                    disabled
                    error={false}
                    sx={{
                      '& .MuiInputBase-inputMultiline': {
                        resize: 'vertical',
                      },

                      '& .Mui-disabled': {
                        cursor: 'not-allowed',
                        background: '#E8E8E8',
                        border: `1.8px solid #${
                          getOutReachValue(contact.latest_event).color
                        }`,
                        borderRadius: '5px',
                        color: '#373737',
                        fontWeight: 600,
                      },
                    }}
                    size="small"
                    onChange={e => {}}
                    fullWidth
                    type="text"
                    placeholder="Outreach Status"
                    id={`latest_event-${index}`}
                  />
                </Box>
              )}
            </>
          )}
          {contact.is_form && (
            <>
              <div>
                <TextFormField
                  nameInput={`name-${index}`}
                  disabled={isAdmin ? false : disableEditButton}
                  label="Name"
                  error={false}
                  required
                  value={contact.name}
                  onChange={(value: string) =>
                    updateField(value, index, 'name')
                  }
                  errorText=""
                  placeholder="Name"
                />
              </div>{' '}
              <div>
                <TextFormField
                  nameInput={`form-url-${index}`}
                  disabled={disableEditButton}
                  label="Form URL"
                  required
                  error={false}
                  value={contact.form_url ?? ''}
                  onBlur={value => {
                    if (value === '') {
                      updateValidURL(false, index);
                      return;
                    }

                    updateValidURL(isURL(value), index);
                  }}
                  onChange={(value: string) => {
                    updateField(value, index, 'form_url');
                  }}
                  errorText=""
                  placeholder="Form URL"
                />
                {contact.form_url !== '' && !isURL(contact.form_url ?? '') && (
                  <Typography fontSize={11} color="red" ml={1}>
                    Invalid URL
                  </Typography>
                )}
              </div>
              <div className="whole-grid">
                <InputLabel
                  htmlFor="notes"
                  sx={{
                    fontSize: 12,
                    fontWeight: '700',
                    color: 'rgba(0, 0, 0, 0.8)',
                    marginBottom: 1,
                  }}
                >
                  Notes
                  <span className="required-fields_dot">*</span>
                </InputLabel>
                <CustomTextAreaField
                  placeholder="Form Text"
                  minRows={4}
                  multiline
                  maxRows={Infinity}
                  disabled={disableEditButton}
                  required
                  value={contact.form_text ?? ''}
                  onChange={e =>
                    updateField(e.target.value, index, 'form_text')
                  }
                />
                {/* <Box justifyContent="end" display="flex">
                  <MainButton
                    variant="contained"
                    sx={{
                      marginTop: 1,
                      backgroundColor: '#BAF372',
                      color: 'black',
                      border: 1,
                      '&:hover': {
                        backgroundColor: '#BAF372',
                      },
                    }}
                    size="small"
                    disabled={template_id === 0}
                    onClick={() => updateNotes(index)}
                  >
                    Generate Template
                  </MainButton>{' '}
                  <MainButton
                    variant="contained"
                    sx={{
                      marginTop: 1,
                      marginX: 2,
                      backgroundColor: '#84D0F0',
                      color: 'black',
                      border: 1,
                      '&:hover': {
                        backgroundColor: '#84D0F0',
                      },
                    }}
                    size="small"
                    disabled={contact.form_text === ''}
                    onClick={() => addTextNote(contact.form_text ?? '')}
                  >
                    Copy To Clipboard
                  </MainButton>{' '}
                </Box> */}
              </div>
              <div>
                <FlexBox>
                  <Box sx={{ flexGrow: 1 }}>
                    <InputLabel
                      htmlFor={`status-contact-${index}`}
                      sx={{
                        fontSize: 12,
                        fontWeight: '700',
                        color: 'rgba(0, 0, 0, 0.8)',
                        marginBottom: 1,
                      }}
                    >
                      Contact Status{' '}
                      <span className="required-fields_dot">*</span>
                    </InputLabel>
                    <Select
                      value={contact.status ?? ''}
                      name={`status-contact-${index}`}
                      id={`status-contact-${index}`}
                      disabled={!isAdmin}
                      displayEmpty
                      fullWidth
                      onChange={e =>
                        updateField(e.target.value, index, 'status')
                      }
                      sx={{ fontSize: 14 }}
                      IconComponent={KeyboardArrowDownIcon}
                      input={<SelectInput />}
                    >
                      <MenuItem value="" disabled>
                        None
                      </MenuItem>
                      {contactStatus.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  {closeTypes.includes(contact.status ?? '') && (
                    <HtmlTooltip title={tooltipInformation()}>
                      <IconButton sx={{ fontSize: 16 }}>
                        <HelpRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </HtmlTooltip>
                  )}
                </FlexBox>
              </div>
              {contact.close_reason &&
                contact.close_reason !== '' &&
                contact.status !== 'open' && (
                  <Box marginTop={2}>
                    <FlexBox>
                      <Box flexGrow={1}>
                        <TextFormField
                          nameInput={`close-reason-${index}`}
                          disabled
                          label="Close Reason"
                          error={false}
                          value={contact.close_reason}
                          onChange={(value: string) =>
                            updateField(value, index, 'name')
                          }
                          errorText=""
                          placeholder="Name"
                        />
                      </Box>
                      {contact.close_reason.length > 60 && (
                        <HtmlTooltip title={contact.close_reason}>
                          <IconButton sx={{ fontSize: 16 }}>
                            <HelpRoundedIcon fontSize="inherit" />
                          </IconButton>
                        </HtmlTooltip>
                      )}
                    </FlexBox>
                  </Box>
                )}
            </>
          )}

          {contact.last_outreach &&
            contact.last_outreach !== '' &&
            !contact.is_form && (
              <Box marginTop={2.1}>
                {!isLowerThan90Days(contact.last_outreach) && (
                  <TextFormField
                    nameInput="last-outreach"
                    disabled
                    label="Last Outreach"
                    error={false}
                    value={dayjs(contact.last_outreach).format('MM/DD/YYYY')}
                    onChange={() => {}}
                    errorText=""
                    placeholder="Last Outreach"
                  />
                )}
                {isLowerThan90Days(contact.last_outreach) && (
                  <>
                    <InputLabel
                      htmlFor="last-outreach"
                      sx={{
                        fontSize: 12,
                        fontWeight: '700',
                        color: 'rgba(0, 0, 0, 0.8)',
                        marginBottom: 1,
                      }}
                    >
                      Last Outreach
                    </InputLabel>
                    <TextInputFieldError
                      value={dayjs(contact.last_outreach).format('MM/DD/YYYY')}
                      name="last-outreach"
                      disabled
                      error
                      size="small"
                      helperText="Contact was recently emailed"
                      fullWidth
                      type="text"
                      id="last-outreach"
                    />
                  </>
                )}
              </Box>
            )}
        </div>
      </Box>
    </Box>
  );
};

interface BasicOptions {
  value: string;
  label: string;
}

interface PropsBasicSelect {
  label?: string;
  nameInput: string;
  value: string;
  options: BasicOptions[];
  onBlur?: (value: string) => void;
  required: boolean;
  disabled: boolean;
  error?: boolean;
  onChange: (value: string) => void;
  filter?: boolean;
}

export const BasicSelect = ({
  label,
  nameInput,
  value,
  options,
  onChange,
  onBlur,
  required,
  disabled,
  filter,
  error,
}: PropsBasicSelect) => (
  <>
    {label && (
      <InputLabel
        htmlFor={nameInput}
        sx={{
          fontSize: 12,
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
          marginBottom: 1,
        }}
      >
        {label} {required && '*'}
      </InputLabel>
    )}
    <Select
      value={value}
      name={nameInput}
      id={nameInput}
      onBlur={e => {
        if (onBlur) onBlur(e.target.value);
      }}
      error={error}
      disabled={disabled}
      displayEmpty
      fullWidth
      onChange={e => onChange(e.target.value)}
      sx={{ fontSize: 14 }}
      className={error ? 'select-error' : ''}
      IconComponent={KeyboardArrowDownIcon}
      input={<SelectInput />}
    >
      <MenuItem value="" disabled={!filter}>
        None
      </MenuItem>
      {options.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  </>
);

interface SelectFormProspectingProps {
  options: BasicArray[];
  onChange: (value: BasicArray | null) => void;
  placeholder: string;
  onBlur?: (value: string) => void;
  error?: boolean;
  disabled: boolean;
  value: BasicArray;
  required: boolean;
  loading?: boolean;
  width?: boolean;
  label?: string;
  bigger?: boolean;
  clear?: boolean;
  onEnter?: () => void;
}

export const SelectFormProspecting = ({
  options,
  placeholder,
  disabled,
  onChange,
  value,
  loading,
  width,
  clear,
  required,
  bigger,
  label,
  onBlur,
  error,
  onEnter,
}: SelectFormProspectingProps) => (
  <>
    {label && (
      <InputLabel
        sx={{
          fontSize: 12,
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
          marginBottom: 1,
        }}
      >
        {label} {required && '*'}
      </InputLabel>
    )}
    <Autocomplete
      disablePortal
      value={value}
      loading={loading}
      disabled={disabled}
      size="small"
      onBlur={e => {
        if (onBlur) {
          const err = e as any;
          onBlur(err.target.value);
        }
      }}
      isOptionEqualToValue={option => value.id === option.id}
      options={options}
      sx={{
        '& .MuiOutlinedInput-root': {
          width: width ? '100%' : '270px',
          padding: '8px 12px',
          '&:focus fieldset': {
            borderColor: '#D7D7D7',
          },
          '&:hover fieldset': {
            borderColor: '#ABABAB',
          },
        },
        '& .Mui-disabled': {
          cursor: 'not-allowed',
          background: '#E8E8E8',
          borderColor: '#D7D7D7',
          color: '#373737',
          fontWeight: 600,
          '&:hover fieldset': {
            borderColor: '#D7D7D7',
          },
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: error ? '2px solid red' : '2px solid #D7D7D7',
        },
        '& input': {
          padding: 0,
          fontSize: bigger ? 14 : 12,
        },
        '& .MuiAutocomplete-tag': {
          fontSize: 12,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      }}
      clearIcon={clear ? <ClearIcon /> : false}
      readOnly={disabled}
      popupIcon={disabled ? null : <KeyboardArrowDownIcon />}
      getOptionLabel={option => option.name}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      onChange={(e, val) => onChange(val)}
      renderInput={params => (
        <TextField
          key={params.id}
          {...params}
          placeholder={loading ? 'Loading...' : placeholder}
          inputProps={{
            ...params.inputProps,
            onKeyDown: e => {
              if (e.key === 'Enter' && onEnter) {
                e.stopPropagation();
                onEnter();
              }
            },
          }}
        />
      )}
    />
  </>
);

interface SelectFormBulkProps {
  options: { id: number; username: string }[];
  onChange: (value: { id: number; username: string } | null) => void;
  placeholder: string;
  disabled: boolean;
  value: { id: number; username: string };
  required: boolean;
  loading?: boolean;
  width?: boolean;
  label?: string;
  bigger?: boolean;
  clear?: boolean;
  onEnter?: () => void;
}

export const SelectFormBulk = ({
  options,
  placeholder,
  disabled,
  onChange,
  value,
  loading,
  width,
  required,
  label,
  clear,
  bigger,
  onEnter,
}: SelectFormBulkProps) => (
  <>
    {label && (
      <InputLabel
        sx={{
          fontSize: 12,
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
          marginBottom: 1,
        }}
      >
        {label} {required && '*'}
      </InputLabel>
    )}
    <Autocomplete
      disablePortal
      value={value}
      loading={loading}
      disabled={disabled}
      size="small"
      isOptionEqualToValue={option => value.id === option.id}
      options={options}
      sx={{
        '& .MuiOutlinedInput-root': {
          width: width ? '100%' : '270px',
          padding: '8px 12px',
          '&:focus fieldset': {
            borderColor: '#D7D7D7',
          },
          '&:hover fieldset': {
            borderColor: '#ABABAB',
          },
        },
        '& .Mui-disabled': {
          cursor: 'not-allowed',
          background: '#E8E8E8',
          borderColor: '#D7D7D7',
          color: '#373737',
          fontWeight: 600,
          '&:hover fieldset': {
            borderColor: '#D7D7D7',
          },
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: '2px solid #D7D7D7',
        },
        '& input': {
          padding: 0,
          fontSize: bigger ? 14 : 12,
        },
        '& .MuiAutocomplete-tag': {
          fontSize: bigger ? 14 : 12,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      }}
      clearIcon={clear ? <ClearIcon /> : false}
      readOnly={disabled}
      popupIcon={disabled ? null : <KeyboardArrowDownIcon />}
      getOptionLabel={option => option.username}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.username}
          </li>
        );
      }}
      onChange={(e, val) => onChange(val)}
      renderInput={params => (
        <TextField
          key={params.id}
          {...params}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            onKeyDown: e => {
              if (e.key === 'Enter' && onEnter) {
                e.stopPropagation();
                onEnter();
              }
            },
          }}
        />
      )}
    />
  </>
);

interface NewAutoSelectFormInputProps {
  options: { id: number; name: string; value: string }[];
  onChange: (value: { id: number; name: string; value: string } | null) => void;
  updateInput?: (value: string) => void;
  placeholder: string;
  disabled: boolean;
  value: { id: number; name: string; value: string };
  required: boolean;
  error?: boolean;
  onBlur?: (value: string) => void;
  loading?: boolean;
  clearIcon?: boolean;
  disabledId?: number;
  noLabel?: boolean;
  errorText?: string;
}

export const NewAutoSelectFormInput = ({
  options,
  placeholder,
  disabled,
  onChange,
  value,
  required,
  loading,
  disabledId,
  error,
  onBlur,
  clearIcon,
  updateInput,
  noLabel,
  errorText,
}: NewAutoSelectFormInputProps) => (
  <>
    {!noLabel && (
      <InputLabel
        sx={{
          fontSize: 12,
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
          marginBottom: 1,
        }}
      >
        {placeholder}{' '}
        {required && <span className="required-fields_dot">*</span>}
      </InputLabel>
    )}
    <Autocomplete
      disablePortal
      value={value}
      loading={loading}
      disabled={disabled}
      // onBlur={e => {
      //   const target = e.target as HTMLInputElement;

      //   const inputValue = target.value;

      //   const matchingOption = options.find(
      //     option => option.value.toLowerCase() === inputValue.toLowerCase(),
      //   );

      //   if (!matchingOption) {
      //     console.log(
      //       'Input value does not match any option, retaining:',
      //       inputValue,
      //     );
      //     if (onBlur) {
      //       onBlur(inputValue);
      //     }
      //   } else {
      //     console.log('Input value matches an option:', matchingOption.name);
      //     if (onBlur) {
      //       onBlur(matchingOption.name);
      //     }
      //   }
      // }}
      size="small"
      isOptionEqualToValue={option => value.value === option.value}
      options={options}
      sx={{
        '& .MuiOutlinedInput-root': {
          padding: '6px 8px',
          '&:focus fieldset': {
            borderColor: '#D7D7D7',
          },
          '&:hover fieldset': {
            borderColor: '#ABABAB',
          },
        },
        '& .Mui-disabled': {
          cursor: 'not-allowed',
          background: '#E8E8E8',
          borderColor: '#D7D7D7',
          color: '#373737',
          fontWeight: 600,
          '&:hover fieldset': {
            borderColor: '#D7D7D7',
          },
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: error ? '2px solid red' : '2px solid #D7D7D7',
        },
        '& input': {
          padding: 0,
          fontSize: 12,
        },
        '& .MuiAutocomplete-tag': {
          fontSize: 12,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: 100,
        },
      }}
      clearIcon={clearIcon}
      readOnly={disabled}
      popupIcon={disabled ? null : <KeyboardArrowDownIcon />}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      renderOption={(props, option) => {
        return (
          <>
            <MenuItem
              {...props}
              key={option.id}
              disabled={option.id === disabledId}
            >
              {option.name}
            </MenuItem>
          </>
        );
      }}
      onChange={(e, val) => {
        if (typeof val === 'string') {
          console.log('Selected value is a string:', val);
        } else {
          console.log('Selected value is an object or null:', val);
          onChange(val);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (updateInput) updateInput(newInputValue);
      }}
      renderInput={params => (
        <>
          <TextField
            key={params.id}
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box
                  component="span"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* Show circle only if an option is selected */}
                  {params.inputProps.value && (
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#7bb0ff',
                        borderRadius: '100%',
                        marginLeft: 5,
                        marginRight: 4,
                      }}
                    />
                  )}
                </Box>
              ),
            }}
            placeholder={loading ? 'Loading...' : placeholder}
          />
          {error && errorText && (
            <FormHelperText error sx={{ ml: 1 }}>
              {errorText}
            </FormHelperText>
          )}
        </>
      )}
    />
  </>
);

interface SelectFormProps {
  options: BasicArray[];
  onChange: (value: BasicArray | null) => void;
  updateInput?: (value: string) => void;
  placeholder: string;
  disabled: boolean;
  value: BasicArray;
  required: boolean;
  error?: boolean;
  onBlur?: (value: string) => void;
  loading?: boolean;
  clearIcon?: boolean;
  disabledId?: number;
  noLabel?: boolean;
  errorText?: string;
}

export const SelectFormInput = ({
  options,
  placeholder,
  disabled,
  onChange,
  value,
  required,
  loading,
  disabledId,
  error,
  onBlur,
  clearIcon,
  updateInput,
  noLabel,
  errorText,
}: SelectFormProps) => (
  <>
    {!noLabel && (
      <InputLabel
        sx={{
          fontSize: 12,
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
          marginBottom: 1,
        }}
      >
        {placeholder}{' '}
        {required && <span className="required-fields_dot">*</span>}
      </InputLabel>
    )}
    <Autocomplete
      disablePortal
      value={value}
      loading={loading}
      disabled={disabled}
      freeSolo
      onBlur={e => {
        const target = e.target as HTMLInputElement;

        const inputValue = target.value;

        const matchingOption = options.find(
          option => option.name.toLowerCase() === inputValue.toLowerCase(),
        );

        if (!matchingOption) {
          console.log(
            'Input value does not match any option, retaining:',
            inputValue,
          );
          if (onBlur) {
            onBlur(inputValue);
          }
        } else {
          console.log('Input value matches an option:', matchingOption.name);
          if (onBlur) {
            onBlur(matchingOption.name);
          }
        }
      }}
      size="small"
      isOptionEqualToValue={option => value.id === option.id}
      options={options}
      sx={{
        '& .MuiOutlinedInput-root': {
          padding: '6px 8px',
          '&:focus fieldset': {
            borderColor: '#D7D7D7',
          },
          '&:hover fieldset': {
            borderColor: '#ABABAB',
          },
        },
        '& .Mui-disabled': {
          cursor: 'not-allowed',
          background: '#E8E8E8',
          borderColor: '#D7D7D7',
          color: '#373737',
          fontWeight: 600,
          '&:hover fieldset': {
            borderColor: '#D7D7D7',
          },
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: error ? '2px solid red' : '2px solid #D7D7D7',
        },
        '& input': {
          padding: 0,
          fontSize: 13,
        },
        '& .MuiAutocomplete-tag': {
          fontSize: 13,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: 100,
        },
      }}
      clearIcon={clearIcon}
      readOnly={disabled}
      popupIcon={disabled ? null : <KeyboardArrowDownIcon />}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      renderOption={(props, option) => {
        return (
          <>
            <MenuItem
              {...props}
              key={option.id}
              disabled={option.id === disabledId}
            >
              {option.name}
            </MenuItem>
          </>
        );
      }}
      onChange={(e, val) => {
        if (typeof val === 'string') {
          console.log('Selected value is a string:', val);
        } else {
          console.log('Selected value is an object or null:', val);
          onChange(val);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (updateInput) updateInput(newInputValue);
      }}
      renderInput={params => (
        <>
          <TextField
            key={params.id}
            {...params}
            placeholder={loading ? 'Loading...' : placeholder}
          />
          {error && errorText && (
            <FormHelperText error sx={{ ml: 1 }}>
              {errorText}
            </FormHelperText>
          )}
        </>
      )}
    />
  </>
);

interface AutoOwnerSelectFormProps {
  options: BasicArray[];
  onChange: (value: BasicArray | null) => void;
  updateInput?: (value: string) => void;
  placeholder: string;
  disabled: boolean;
  value: BasicArray;
  required: boolean;
  error?: boolean;
  onBlur?: (value: string) => void;
  loading?: boolean;
  clearIcon?: boolean;
  disabledId?: number;
  noLabel?: boolean;
  errorText?: string;
}

export const AutoOwnerSelectFormInput = ({
  options,
  placeholder,
  disabled,
  onChange,
  value,
  required,
  loading,
  disabledId,
  error,
  onBlur,
  clearIcon,
  updateInput,
  noLabel,
  errorText,
}: AutoOwnerSelectFormProps) => (
  <>
    {!noLabel && (
      <InputLabel
        sx={{
          fontSize: 12,
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
          marginBottom: 1,
        }}
      >
        {placeholder}{' '}
        {required && <span className="required-fields_dot">*</span>}
      </InputLabel>
    )}
    <Autocomplete
      disablePortal
      value={value}
      loading={loading}
      disabled={disabled}
      freeSolo
      onBlur={e => {
        const target = e.target as HTMLInputElement;

        const inputValue = target.value;

        const matchingOption = options.find(
          option => option.name.toLowerCase() === inputValue.toLowerCase(),
        );

        if (!matchingOption) {
          console.log(
            'Input value does not match any option, retaining:',
            inputValue,
          );
          if (onBlur) {
            onBlur(inputValue);
          }
        } else {
          console.log('Input value matches an option:', matchingOption.name);
          if (onBlur) {
            onBlur(matchingOption.name);
          }
        }
      }}
      size="small"
      isOptionEqualToValue={option => value.id === option.id}
      options={options}
      sx={{
        '& .MuiOutlinedInput-root': {
          padding: '6px 8px',
          '&:focus fieldset': {
            borderColor: '#D7D7D7',
          },
          '&:hover fieldset': {
            borderColor: '#ABABAB',
          },
        },
        '& .Mui-disabled': {
          cursor: 'not-allowed',
          background: '#E8E8E8',
          borderColor: '#D7D7D7',
          color: '#373737',
          fontWeight: 600,
          '&:hover fieldset': {
            borderColor: '#D7D7D7',
          },
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: error ? '2px solid red' : '2px solid #D7D7D7',
        },
        '& input': {
          padding: 0,
          fontSize: 12,
        },
        '& .MuiAutocomplete-tag': {
          fontSize: 12,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: 100,
        },
      }}
      clearIcon={clearIcon}
      readOnly={disabled}
      popupIcon={disabled ? null : <KeyboardArrowDownIcon />}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      renderOption={(props, option) => {
        return (
          <>
            <MenuItem
              {...props}
              key={option.id}
              disabled={option.id === disabledId}
            >
              {option.name}
            </MenuItem>
          </>
        );
      }}
      onChange={(e, val) => {
        if (typeof val === 'string') {
          console.log('Selected value is a string:', val);
        } else {
          console.log('Selected value is an object or null:', val);
          onChange(val);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (updateInput) updateInput(newInputValue);
      }}
      renderInput={params => (
        <>
          <TextField
            key={params.id}
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box
                  component="span"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* Show circle only if an option is selected */}
                  {params.inputProps.value && (
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#d9d9d9',
                        borderRadius: '100%',
                        marginLeft: 5,
                        marginRight: 4,
                      }}
                    />
                  )}
                </Box>
              ),
            }}
            placeholder={loading ? 'Loading...' : placeholder}
          />
          {error && errorText && (
            <FormHelperText error sx={{ ml: 1 }}>
              {errorText}
            </FormHelperText>
          )}
        </>
      )}
    />
  </>
);

interface AutoCompletePropsInput {
  options: { label: string; value: string }[];
  onChange: (value: { label: string; value: string } | null) => void;
  placeholder: string;
  disabled: boolean;
  value: { label: string; value: string };
  required: boolean;
}

export const AutoCompleteInput = ({
  options,
  placeholder,
  disabled,
  onChange,
  value,
  required,
}: AutoCompletePropsInput) => (
  <>
    <InputLabel
      sx={{
        fontSize: 12,
        fontWeight: '700',
        color: 'rgba(0, 0, 0, 0.8)',
        marginBottom: 1,
      }}
    >
      {placeholder} {required && '*'}
    </InputLabel>
    <Autocomplete
      disablePortal
      value={value}
      disabled={disabled}
      size="small"
      isOptionEqualToValue={option => value.value === option.value}
      options={options}
      sx={{
        '& .MuiOutlinedInput-root': {
          padding: '8px 12px',
          '&:focus fieldset': {
            borderColor: '#D7D7D7',
          },
          '&:hover fieldset': {
            borderColor: '#ABABAB',
          },
        },
        '& .Mui-disabled': { cursor: 'not-allowed' },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: '2px solid #D7D7D7',
        },
        '& input': {
          padding: 0,
          fontSize: 14,
        },
        '& .MuiAutocomplete-tag': {
          fontSize: 12,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: 100,
        },
      }}
      clearIcon={false}
      popupIcon={<KeyboardArrowDownIcon />}
      getOptionLabel={option => option.label}
      onChange={(e, val) => onChange(val)}
      renderInput={params => (
        <TextField {...params} placeholder={placeholder} />
      )}
    />
  </>
);

interface TagProps {
  value: BasicArray[];
  options: BasicArray[];
  updateSelect: (value: BasicArray[]) => void;
  disabled: boolean;
  placeholder: string;
  required: boolean;
  hideLabel?: boolean;
  limitTags?: number;
  onBlurFunc?: () => void;
  onEnter?: () => void;
}

export const TagsSelect = ({
  value,
  disabled,
  options,
  updateSelect,
  placeholder,
  required,
  hideLabel,
  limitTags,
  onBlurFunc,
  onEnter,
}: TagProps) => (
  <>
    {!hideLabel && (
      <InputLabel
        sx={{
          fontSize: 12,
          fontWeight: '700',
          color: 'rgba(0, 0, 0, 0.8)',
          marginBottom: 1,
        }}
      >
        {placeholder} {required && '*'}
      </InputLabel>
    )}
    <Autocomplete
      multiple
      value={value}
      disabled={disabled}
      disableCloseOnSelect
      onBlur={onBlurFunc}
      isOptionEqualToValue={(option, test) => option.id === test.id}
      clearIcon={false}
      size="small"
      limitTags={limitTags ?? 6}
      sx={{
        '& .MuiOutlinedInput-root': {
          padding: '8px 12px',
          '&:focus fieldset': {
            borderColor: '#D7D7D7',
          },
          '&:hover fieldset': {
            borderColor: '#ABABAB',
          },
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          border: '2px solid #D7D7D7',
        },
        '& input': {
          padding: 0,
          fontSize: 14,
        },
        '& .MuiAutocomplete-tag': {
          fontSize: 13,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: 120,
          borderRadius: '3px',
        },
      }}
      options={options}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <DefaultCheckbox
            size="small"
            checked={selected}
            sx={{ padding: 0, marginRight: 1.3 }}
          />
          {option.name}
        </li>
      )}
      onChange={(e, val) => updateSelect(val)}
      popupIcon={<KeyboardArrowDownIcon />}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            onKeyDown: e => {
              if (e.key === 'Enter' && onBlurFunc) {
                e.stopPropagation();
                onBlurFunc();
                if (onEnter) onEnter();
              }
            },
          }}
        />
      )}
    />
  </>
);
